import tw from 'twin.macro';
import * as Constant from '../../../../constant/index';

const CourseLatestSprite = tw.i`bg-course-latest-sprite bg-no-repeat`;
const Sprite120 = tw.i`bg-sprite1-20 bg-no-repeat`;
const ExperienceSprite15 = tw.div`bg-experience1-5 bg-no-repeat`;

const CourseIconZoom = tw.i`bg-course-latest-sprite bg-no-repeat`;

const DownArrow = tw.i`bg-header_sprite bg-no-repeat`;
const HeaderSprite = tw.i`bg-header_sprite bg-no-repeat`

const CourseLatestSprite2 = tw.i`bg-course-latest-sprite bg-no-repeat`;

const FAQPlusIcon = tw.i`bg-faq_plus_icon bg-no-repeat`

export const DiscountOffer = tw(CourseLatestSprite)`bg-position-discount-offer w-[22px] h-[22px] inline-block align-middle`;

export const WhoWillAttendIcon = tw(
  CourseLatestSprite
)`bg-position-who-will-attend-list-icon w-[22px] h-[22px] top-0 absolute left-0`;

export const InstructorIcon = tw(Sprite120)`bg-position-instructor-icon w-[22px] h-[29px]`;

export const ExperienceIcon = tw(ExperienceSprite15)`bg-position-experience1-5-icon w-[72px] h-[86px]`;

export const FormInvalidIcon = tw(
  CourseLatestSprite
)`bg-position-form-invalid-icon w-[12px] h-[12px] absolute bottom-3 right-[5px]`;

export const FormValidIcon = tw(
  CourseLatestSprite
)`bg-position-form-valid-icon w-[12px] h-[12px] absolute bottom-3 right-[5px]`;

export const RightSideStickyLink = tw(CourseLatestSprite)`bg-position-right-sticky-accordion-link w-8 h-8 inline-block`;

export const IconZoom = tw(CourseIconZoom)`bg-position-icon-zoom w-[34px] h-[34px]`;
export const DownArrowIcon = tw(DownArrow)`inline-block bg-position-arrow-down w-[14px] h-[9px]`;

export const RightArrow = tw(CourseLatestSprite)`bg-right-arrow w-[12px] h-[22px] inline-block`;

export const WhoWillAttendIcon2 = tw(
  FAQPlusIcon
)`bg-position-who-will-attend-list-icon-2 w-[22px] h-[22px] top-[2px] absolute left-0`;

export const IconPlus = tw(FAQPlusIcon)`bg-position-icon-plus w-[14px] h-[14px] inline-block`
export const IconMinus = tw(CourseLatestSprite)`bg-position-icon-minus w-[14px] h-[14px] inline-block`
export const IconBlackArrowDown = tw(HeaderSprite)`bg-position-icon-pink-arrow-up w-[14px] h-[8px] inline-block `
export const IconPinkArrowUp = tw(HeaderSprite)`bg-position-icon-pink-arrow-up w-[12px] h-[8px] inline-block `
export const CartIcon = tw(HeaderSprite)`bg-position-icon-cart-large w-[24px] h-[20px] inline-block  `
interface IIcon {
  icon: string;
  viewBox?: string;
  width?: string;
  height?: string;
  fill?: string;
}

const Icon: React.FC<IIcon> = ({ icon, viewBox = '0 0 1024 1024', width, height, fill = '' }) => (
  <svg fillRule="evenodd" width={width} height={height} viewBox={viewBox}>
    <path d={Constant.ICONS[icon]} fill={fill}></path>
  </svg>
);

export default Icon;
