import React, { useState } from "react";
import { HeaderMenuIcon, SearchIcon } from '../Icon';
import { MenuItemsProps } from '../MenuItems/Interface';
import HeaderIconSmallScreen from "./HeaderIconSmallScreen";
import HeaderSearchBar from './HeaderSearchBar';
import HeaderSideBar from './HeaderSideBar';
import HeaderTopSideBar from "./HeaderTopSideBar";

type Props = {
  MenuItemsProps: MenuItemsProps;
  MegaMenuProps: any;
};

const HeaderSmallScreen: React.FC = () => {
  const [isShowSideBar, setIsShowSideBar] = React.useState<any>(false);

  const [side, setSide] = useState<"top" | "left" | null>(null);

  const [isShowSideTopBar, setIsShowSideTopBar] =
    React.useState<boolean>(false);

  const [searchBar, setSearchBar] = React.useState<boolean>(false);

  const changeSideTopBarStatus: React.MouseEventHandler<HTMLDivElement> =
    () => {
      setSide("top");
      setIsShowSideTopBar(true);
    };

  const changeSideBarStatus: React.MouseEventHandler<HTMLDivElement> = () => {
    setSide("left");
    setIsShowSideBar(true);
  };

  const closeAllSideBars = () => {
    setSide(null);
    isShowSideBar && setIsShowSideBar(false);
    isShowSideTopBar && setIsShowSideTopBar(false);
  };

  const changeSearchBarStatus: React.MouseEventHandler<HTMLDivElement> = () => {
    setSearchBar(!searchBar);
  };

  return (
    <nav className="lg:hidden">
      {/* Header */}
      <div className="flex justify-between items-center h-14">
        <div className="flex items-center">
          <div
            className="cursor-pointer mr-[19px] h-3 rounded-full md:hover:bg-gray-100 inline-flex"
            onClick={changeSideBarStatus}
          >
            <HeaderMenuIcon />
          </div>

          <HeaderIconSmallScreen />
        </div>
        <div className="flex items-center">
          <div
            className="border border-custom-gray-500 rounded mr-[17px] w-16 h-7 flex items-center justify-center font-semibold text-xs leading-4 text-custom-gray-150 cursor-pointer"
            onClick={changeSideTopBarStatus}
          >
            Courses
          </div>

          <div
            className="cursor-pointer rounded-full inline-flex md:hover:bg-gray-100"
            onClick={changeSearchBarStatus}
          >
            <SearchIcon />
          </div>
        </div>
      </div>

      {/* Search bar */}
      {searchBar && <HeaderSearchBar changeSearchBarStatus={changeSearchBarStatus} />}

      {/* Side bar */}
      <HeaderSideBar sideBar={isShowSideBar || isShowSideTopBar}
        side={side}
        closeAllSideBars={closeAllSideBars} />

      {/* Top Side bar */}
      <HeaderTopSideBar
        sideBar={isShowSideBar || isShowSideTopBar}
        side={side}
        closeAllSideBars={closeAllSideBars}
      />
    </nav>
  );
};

export default HeaderSmallScreen;
