import { GlobalContext } from '../../../pages/_app';
import { FC, useContext } from 'react';
import Button from '../../../components/layout-a/components/common/Button';
import { CallBtn } from '../Icon';
import { getPhoneNumberFromCountryId } from '../PhoneNumbers';

const HeaderSidebarContactUs: FC<any> = ({ openSubscribeModal }) => {
  const {countryId} = useContext(GlobalContext);
  const phoneNumber = getPhoneNumberFromCountryId(countryId, "phone");
  return (
    <div className={`border-t-[3px] border-[#eeeeee] p-4 px-4 pb-4 w-full h-[178px] bg-white text-center`}>
      <div className="font-semibold text-xs leading-4 mb-2">Did not find what you are looking for?</div>

      <div className="font-regular text-[10px] leading-[14px] text-[#666666] mb-4">We’ll help you find it</div>

      <div className="flex justify-center mb-6">
        <div className="w-auto h-[30px] border border-[#111111] rounded flex items-center justify-center font-semibold text-sm py-[5px] px-5">
          <a href={`tel:${phoneNumber}`} rel="nofollow" className="inline-flex items-center">
            <CallBtn className="mr-[5px]" />
            Call us
          </a>
        </div>
      </div>

      <a rel="nofollow" href="https://prism.knowledgehut.com/">
        <Button
          type="primary"
          className="font-semibold text-sm leading-[14px] h-[35px] text-white bg-gradient-btn border-none border-0 rounded p-2 flex justify-center"
        >
          Sign In
        </Button>
      </a>
    </div>
  );
};

export default HeaderSidebarContactUs;
