import Link from 'next/link';
import React, { useState } from 'react';
import tw from 'twin.macro';
import { FooterAccordionArrow } from '../Icon';
import { companyService, partnerService, supportService, resourceService, offeringService ,  topCategories, topCourses } from './data';
import FooterStories from './Footer.stories';

type ServiceItem = {
  name: string;
  href: string;
  rel?: number;
  dataTrackerId?: string;
  noFollow?: boolean;
  targetBlank?: boolean;
};

const ServiceWrapper = tw.section`border-t md:border-b border-[#222222] py-0 md:py-10`;

const DesktopServiceItem: React.FC<{ name: string; service: ServiceItem[] }> = ({ name, service }) => {
  return (
    <div>
      <p tw="text-[21px] font-semibold h-11 xl:h-auto ">{name}</p>
      <ul tw="pt-6">
        {service.map(({ name, href, rel, dataTrackerId, noFollow, targetBlank }, idx) => (
          <li key={idx} tw="text-sm h-10 leading-7 xl:h-auto">
            {rel == 1 && dataTrackerId?.length ? (
              <a
                href={href}
                title={name}
                rel="nofollow"
                data-tracker-id={dataTrackerId}
                target={targetBlank && '_blank'}
              >
                {name}
              </a>
            ) : dataTrackerId?.length ? (
              <a
                href={href}
                title={name}
                data-tracker-id={dataTrackerId}
                target={targetBlank && '_blank'}
                rel={noFollow && 'nofollow'}
              >
                {name}
              </a>
            ) : (
              <a href={href} title={name} target={targetBlank && '_blank'} rel={noFollow && 'nofollow'}>
                {name}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const MobileServiceItem: React.FC<{
  isActive: boolean;
  name: string;
  service: ServiceItem[];
  onClick: (name: string) => void;
  dataTrackerId?: string;
}> = ({ isActive, name, service, onClick, dataTrackerId }) => {
  return (
    <div tw="text-[#ebebeb] border-b border-[#222222] ">
      <div tw="relative flex items-center w-full text-left py-4 text-sm leading-[14px] font-semibold" onClick={() => onClick(name)}>
        {name}
        <span tw="absolute right-0 transform">
          <FooterAccordionArrow
            className={`icon-down-arrow-white blog-icons inline-block transition-transform duration-300 rotate-180 ${
              isActive && 'rotate-0'
            }`}
          />
        </span>
      </div>
      <div tw="transition-all duration-300 overflow-hidden max-h-0" css={[isActive && tw`max-h-96`]}>
        <ul tw="px-3 pb-4">
          {service.map(({ name, href, rel, dataTrackerId, noFollow, targetBlank }, idx) => (
            <li key={idx} tw="text-xs leading-8">
              {/* {rel == 1 && dataTrackerId?.length ? (
                <a href={href} title={name} rel="nofollow" data-tracker-id={dataTrackerId}>
                  {name}
                </a>
              ) : dataTrackerId?.length ? ( */}
                <a href={href} title={name} data-tracker-id={dataTrackerId} 
                target={targetBlank && '_blank'}
                rel={noFollow  && 'nofollow'}>
                  {name}
                </a>
              {/* ) : (
                <a href={href} title={name}>
                  {name}
                </a>
              )} */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SectionServices: React.FC = () => {
  const [serviceActive, setService] = useState<string>('Company');

  const onServiceClick = (name: string) => {
    setService((service) => {
      if (service === name) {
        return '';
      }

      return name;
    });
  };
  return (
    <ServiceWrapper>
      <div tw="md:hidden md:invisible">
        <MobileServiceItem
          name="Company"
          service={companyService}
          isActive={serviceActive === 'Company'}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Offerings"
          service={offeringService}
          isActive={serviceActive === 'Offerings'}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Resources"
          service={resourceService}
          isActive={serviceActive === 'Resources'}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Partner with us"
          service={partnerService}
          isActive={serviceActive === 'Partner with us'}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Support"
          service={supportService}
          isActive={serviceActive === 'Support'}
          onClick={onServiceClick}
        />
          <MobileServiceItem
          name="Top Categories"
          service={topCategories}
          isActive={serviceActive === 'Top Categories'}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Top Courses"
          service={topCourses}
          isActive={serviceActive === 'Top Courses'}
          onClick={onServiceClick}
        />
      </div>
      <div tw="md:grid md:grid-cols-5 md:visible invisible hidden ">
        <DesktopServiceItem name="Company" service={companyService} />
        <DesktopServiceItem name="Offerings" service={offeringService} />
        <DesktopServiceItem name="Resources" service={resourceService} />
        <DesktopServiceItem name="Partner with us" service={partnerService} />
        <DesktopServiceItem name="Support" service={supportService} />
      </div>
    </ServiceWrapper>
  );
};

export default SectionServices;
