import { GlobalContext } from '../../pages/_app';
import { useState, useEffect, useRef, useContext } from 'react';

interface options {
  root?: any;
  rootMargin?: any;
  threshold?: any;
}

const XDefaults: options = {
  root: null,
  rootMargin: '0px',
  threshold: [0.1],
};

const ViewPortCheck = (options?: options) => {
  const { showRHS, setShowRHS } = useContext(GlobalContext);
  const viewRef = useRef<HTMLDivElement>();
  const [inView, setInView] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;

    setInView(entry.isIntersecting);
    setShowRHS(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);

    if (viewRef.current) observer.observe(viewRef.current);

    return () => {
      if (viewRef.current) observer.unobserve(viewRef.current);
    };
  }, []);

  return [viewRef, inView];
};

export default ViewPortCheck;
