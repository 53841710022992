import { getCoursesBySearch } from '../../../lib/api/course';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { SearchCloseIcon, SearchIcon } from '../Icon';
import TrendingDropdown from '../TrendingDropdown/TrendingDropdown';
import { trackGa4Events } from '../../../lib/events/ga4/tracker';

const SearchBar: React.FC = () => {
  const ref = useRef();
  const [query, setQuery] = React.useState('');
  const router = useRouter();

  const [focus, setFocus] = useState(false);

  const [coursesList, setCoursesList] = useState([]);

  // Input focus
  const toggleFocus = () => {
    getCourses('');
    setFocus(true);
  };

  // Input changes
  const handleChange = (value: string) => {
    setQuery(value);
    handleSearch(value);
  };

  // Resets / empties search bar
  const reset = () => {
    setQuery('');
    setCoursesList([]);
    setFocus(false);
  };

  // Checks pressed key
  const checkKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && query.trim().length) {
       
      // Track Ga4 events
      trackGa4Events('all_page_header_click_search');
      window.location.href = `/search?q=${query}`;
    }
  };

  // Search
  const handleSearch = async (value = '') => {
    if (value) {
      await getCourses(value);
    } else if (query.trim().length) {
      await getCourses(query);
    }    
  };

  // getCourses
  const getCourses = async (value) => {
    const courses = await getCoursesBySearch(value);
    // setCoursesList
    setCoursesList(courses);
  };

  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (e.target.id !== 'searchBox' && e.target.id !== 'searchList') {
        reset();
      }
    };
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <div className="flex items-center relative group">
      <input
        className="w-full h-[38px] text-[13px] font-semibold p-2 px-11 border border-gray-8 border-[#cccccc] rounded focus-visible:outline-none placeholder-shown:text-[13px] placeholder-shown:text-custom-gray-150 text-black placeholder:text-custom-gray-150 placeholder-shown:font-regular"
        type="text"
        placeholder="What do you want to learn"
        value={query}
        onFocus={toggleFocus}
        onChange={(event) => handleChange(event.target.value)}
        onKeyPress={(event) => checkKey(event)}
        id="searchBox"
      />
      {/* Search icon */}
      <div
        className="absolute left-[18px] top-1/2 h-5 -translate-y-1/2 cursor-pointer"
        onClick={() => {
          trackGa4Events('all_page_header_click_search');
          router.push(`/courses`);
        }}
      >
        <SearchIcon />
      </div>
      {/* Cancel icon */}

      {focus && (
        <div className="z-50 absolute right-[12px] top-1/2 h-[14px] -translate-y-1/2 cursor-pointer" onClick={reset}>
          <SearchCloseIcon />
        </div>
      )}

      {/* Trending courses */}
      <div className="absolute top-0 mt-[38px] w-full ">
        <TrendingDropdown data={coursesList} />
      </div>
    </div>
  );
};

export default SearchBar;
