import { GlobalContext } from '../../../pages/_app';
import React, { useContext, useEffect, useState } from 'react';

declare const window: any;

const ShadowAuthButton: React.FC = () => {
  const { isLoadSiginButton, setIsLoadSigninButton } = useContext(GlobalContext);

  useEffect(() => {
    if (isLoadSiginButton) {
      window.authShadowRoot = document.querySelector('#auth-shadow-container')?.attachShadow({ mode: 'open' });
      if (window.authShadowRoot) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';

        link.href = `${process.env.NEXT_PUBLIC_AUTH_CSS}`;
        window?.authShadowRoot.appendChild(link);

        const link2 = document.createElement('link');
        link2.rel = 'stylesheet';
        link2.href = 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap';
        window?.authShadowRoot.appendChild(link2);

        const script = document.createElement('script');
        script.src = `${process.env.NEXT_PUBLIC_AUTH_JS}`;
        window?.authShadowRoot.appendChild(script);

        const dom = document.createElement('div');
        dom.id = 'auth-button-root';
        window?.authShadowRoot.appendChild(dom);

        const style = document.createElement('style');
        style.innerHTML = `.authButton__axfbknm  {font-weight: 600;text-transform: capitalize;border: 1px solid rgba(167, 165, 166, 0) !important;height: 36px !important;padding: 8px 9px !important;border-radius: 4px;font-size: 13px !important;line-height: 21px !important; background-color: #f0f0f0 !important;} #auth-button-root {width:62px;}`;
        window?.authShadowRoot.appendChild(style);
      }
    } else {
      const obj = document.querySelector('#auth-shadow-container');
      const pDiv = obj.parentNode;
      pDiv.removeChild(obj);

      const newDiv = document.createElement('div');
      newDiv.id = 'auth-shadow-container';
      pDiv.appendChild(newDiv);
    }
  }, [isLoadSiginButton]);

  return <div id="auth-shadow-container"></div>;
};

export default ShadowAuthButton;
