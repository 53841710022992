import camelcase from 'camelcase';

// camelCaseConvertor
export function camelCaseConvertor(data: any) {
	let result: any = [];

	Object.keys(data).forEach((key) => {
		let camelCaseKey = camelcase(key);

		result[camelCaseKey] = data[key];
	});

	return result;
}
