// import Link from "next/link";
import { useRouter } from 'next/router';
import { getCoursesBySearch } from '../../../lib/api/course';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { SearchCloseIcon, SearchIcon } from '../Icon';
import TrendingDropdown from '../TrendingDropdown/TrendingDropdown';
// import { TrendingCoursesProps } from "./interface";

type Props = {
  changeSearchBarStatus: MouseEventHandler<HTMLDivElement> | any;
};

const HeaderSearchBar: React.FC<Props> = ({ changeSearchBarStatus }) => {
  const [query, setQuery] = React.useState('');
  const router = useRouter();

  const [focus, setFocus] = useState(false);

  const [coursesList, setCoursesList] = useState([]);

  // Input focus
  const toggleFocus = () => {
    getCourses('');
    setFocus(true);
  };

  // Input changes
  const handleChange = (value: string) => {
    setQuery(value);
    handleSearch(value);
  };

  // Resets / empties search bar
  const reset = () => {
    setQuery('');
    setCoursesList([]);
    setFocus(false);
    changeSearchBarStatus();
  };

  // Checks pressed key
  const checkKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && query.trim().length) {
      window.location.href = `/search?q=${query}`;
    }
  };

  // Search
  const handleSearch = async (value = '') => {
    if (value) {
      await getCourses(value);
    } else if (query.trim().length) {
      await getCourses(query);
    }
  };

  // getCourses
  const getCourses = async (value) => {
    const courses = await getCoursesBySearch(value);
    // setCoursesList
    setCoursesList(courses);
  };

  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (e.target.id !== 'searchBox' && e.target.id !== 'searchList') {
        reset();
      }
    };
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <div>
      <div className="absolute left-0 top-0 px-2 flex justify-between items-center bg-white w-full h-14 border-b sm:px-3">
        {/* Search icon */}
        <div
          className="cursor-pointer md:hover:bg-gray-100 rounded-full py-1 px-[10px]"
          onClick={() => {
            router.push(`/courses`);
          }}
        >
          <SearchIcon className="mt-[6px]" />
        </div>

        {/* Input box */}
        <div className="w-11/12">
          <input
            id="searchBox"
            type="text"
            placeholder="What do you want to learn"
            className="border-none outline-none w-full text-sm text-gray-600 focus-visible:outline-none"
            value={query}
            onKeyPress={(event) => checkKey(event)}
            onChange={(event) => handleChange(event.target.value)}
            onFocus={toggleFocus}
            onBlur={toggleFocus}
          />
        </div>

        {/* Close icon */}
        <div className="cursor-pointer md:hover:bg-gray-100 rounded-full py-1 px-2" onClick={reset}>
          <SearchCloseIcon className="mt-[6px] ml-[4px]" />
        </div>
      </div>

      {/* Trending courses */}
      {focus && (
        <div className="absolute w-full left-0 flex justify-center z-10 md:w-full">
          <div className="w-[80%] md:w-full">
            <TrendingDropdown data={coursesList} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderSearchBar;
