import { DocumentNode } from 'graphql';
import apolloClient from './apolloConnection';

interface gqlParams {
  query: DocumentNode;
  variables: object;
  queryName: any; // need to change
  queryType?: 'query' | 'mutation' | 'query-without-edge';
  context?: string;
}

const gqlDefaults: gqlParams = {
  query: undefined,
  variables: undefined,
  queryName: undefined,
  queryType: 'query',
  context: null,
};

const gql = async (gqlParams: gqlParams) => {
  let { query, variables, queryName, queryType, context } = { ...gqlDefaults, ...gqlParams };
  try {
    // console.log(query);
    // console.log(variables);
    const result = await apolloClient.query({ query, variables });

    if (queryType == 'query-without-edge') {
      return result.data[queryName];
    }

    if (queryType == 'query') {
      return result.data[queryName].edges.map((obj) => {
        return obj.node;
      });
    }
    return result;
  } catch (error) {
    console.error(`${context} ${error}`);
  }
};

export default gql;
