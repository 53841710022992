import axios from 'axios';
import { GET_CITY_BY_SLUG, GET_ILB_DATA } from '../../lib/graphql/constants/city.gql';

import gql from '../../lib/graphql/gql';
import { camelCaseConvertor } from './camelCaseConvertor';

// getCityBySlug2
export async function getCityBySlug(slug: string) {
  const cityData = await gql({
    query: GET_CITY_BY_SLUG,
    variables: { filter: { slug: { eq: slug }, status: { eq: 1 } } },
    queryName: 'cities',
    queryType: 'query',
    context: 'getCityBySlug',
  });

  if (!cityData?.length) return null;

  return camelCaseConvertor(cityData);
}

//getILBData

export async function getILBData(props: { courseSlug: string; countryID: number; cityID: number; pageType: number }) {
  //props
  let { courseSlug, countryID, cityID, pageType } = props;

  // ilbFilter
  let ilbFilter;

  if (pageType == 1) {
    ilbFilter = { country_id: { eq: countryID }, ilb_order_by: { neq: 0 } };
   // courseSlug = courseSlug.split('-').slice(0, -1).join('-');
  } else {
    ilbFilter = { country_id: { eq: countryID }, ilb_order_by: { neq: 0 }, legacy_id: { neq: cityID } };
  }

  const result = await gql({
    query: GET_ILB_DATA,
    variables: {
      filter: ilbFilter,
      paging: { first: 10 },
      sorting: { direction: 'DESC', field: 'ilb_order_by' },
    },
    queryName: 'cities',
    queryType: 'query',
    context: 'GET ILB DATA',
  });

  const data = result?.map((obj) => {
    return { slug: `${courseSlug}${'-'}${obj.slug}`, name: obj.name };
  });

  return data || [];
}
