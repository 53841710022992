import { useEffect, useState } from 'react';
import TopBanner from '../TopBanner';
import HeaderLargeScreen from './HeaderLargeScreen';
import HeaderSmallScreen from './HeaderSmallScreen';
import EnquiryNumber from '../EnquiryNumber';
import { getPhoneConfigById } from '../../../lib/api/phone';

interface PostsProps {
  isShowTopBanner: boolean;
  topBannerData: any;
  phoneNumber?: string;
}

const Header: React.FC<PostsProps> = ({ isShowTopBanner, topBannerData, phoneNumber }) => {
  return (
    <>
      {/* {phoneNumber && <EnquiryNumber phoneNumber={phoneNumber} />} */}
      {isShowTopBanner && <TopBanner topBannerData={topBannerData?.length > 0 && topBannerData[0]} />}
      <header className="bg-white md:px-[50px] xs:px-[15px] lg:h-[68px] xs:h-[56px] relative z-[102] shadow-header">
        <HeaderLargeScreen />
        <HeaderSmallScreen />
      </header>
    </>
  );
};

export default Header;
