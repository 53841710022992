export const EventData = {
  submit_exitpopup: {
    label: 'Download Free Guide',
    name: 'Submit',
    action: 'Submit',
  },
  // done
  course_viewed: {
    label: 'Course Viewed',
    name: 'Course Viewed',
    action: 'Course Viewed',
  },
  // done
  click_view_schedule_banner: {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Banner',
    action: 'Clicked on view schedules',
    message: '',
  },
  // done
  click_view_schedule_online_classroom_rhs: {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Online Classroom RHS',
    action: 'Clicked on view schedules',
    message: '',
  },
  // done
  click_view_schedule_classroom_rhs: {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Classroom RHS',
    action: 'Clicked on view schedules',
    message: '',
  },
  // done
  click_enroll_now_osl_rhs: {
    label: 'View Schedule Click',
    name: 'Click_Enroll Now_OSL RHS',
    action: 'Clicked on view schedules',
    message: '',
  },
  // done
  click_read_more_testimonials_reviews: {
    label: 'Clicked on read more testimonials',
    name: 'Click_Read More Testimonials_Reviews',
    action: 'Clicked on read more testimonials',
  },
  // done
  click_share_banner_whatsapp: {
    label: 'Clicked on Share',
    name: 'Click_share_Banner',
    action: 'Clicked on Share',
    socialMedia: 'Whatsapp',
  },
  // done
  click_share_banner_facebook: {
    label: 'Clicked on Share',
    name: 'Click_share_Banner',
    action: 'Clicked on Share',
    socialMedia: 'Facebook',
  },
  // done
  click_share_banner_twitter: {
    label: 'Clicked on Share',
    name: 'Click_share_Banner',
    action: 'Clicked on Share',
    socialMedia: 'Twitter',
  },
  // done
  click_share_banner_linkedin: {
    label: 'Clicked on Share',
    name: 'Click_share_Banner',
    action: 'Clicked on Share',
    socialMedia: 'Linkedin',
  },
  // footer & header done
  click_faqs: {
    label: "Clicked on FAQ's",
    name: "Click_FAQ's",
    action: "Clicked on FAQ's",
  },
  // footer & header done
  click_practice_test: {
    label: 'Clicked on Practice Test',
    name: 'Click_Practice Test',
    action: 'Clicked on Practice Test',
  },
  // footer & header done
  click_interview_questions: {
    label: 'Clicked on Interview Questions',
    name: 'Click_Interview Questions',
    action: 'Clicked on Interview Questions',
  },
  // footer & header done
  click_blogs: {
    label: 'Clicked on Blogs',
    name: 'Click_Blogs',
    action: 'Clicked on Blogs',
  },
  // footer & header done
  click_tutorials: {
    label: 'Clicked on Tutorials',
    name: 'Click_Tutorials',
    action: 'Clicked on Tutorials',
  },
  // done
  click_show_discount_individual_group_enroll: {
    label: 'Show Discount Click',
    name: 'Click_Show Discount_Individual Group Enroll',
    action: 'Click',
  },
  // done
  click_become_an_instructor_instructor: {
    label: 'Become An Instructor Click',
    name: 'Click_Become An Instructor_Instructor',
    action: 'Click',
  },
  // done
  click_view_schedule_curriculum: {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Curriculum',
    action: 'Click',
  },
  // done
  submit_drop_a_query_footer_sticky: {
    label: 'Drop a Query Form Submit',
    name: 'Submit_Drop A Query_Footer Sticky',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  // done
  submit_request_for_call_back_footer_sticky: {
    label: 'Request a Call Back Form Submit',
    name: 'Submit_Request For Call Back_Footer Sticky',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  // done
  ' Submit_Contact Learning Advisor_CTA1': {
    label: 'Course Advisor Form Submit',
    name: ' Submit_Contact Learning Advisor_CTA1',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  // done
  'Submit_Talk To Scrum Expert_Why GCP KH': {
    label: 'Request For A Custom Quote Form Submit',
    name: 'Submit_Talk To Scrum Expert_Why GCP KH',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  // done
  'Submit_Request To Download Syllabus_Page Top Navigation': {
    label: 'Download Brochure Form Submit',
    name: 'Submit_Request To Download Syllabus_Page Top Navigation',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  // done
  submit_subscription_footer: {
    label: 'Subscription',
    name: 'submit_subscription_footer',
    action: 'NewsLetter Subscription',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  // Layout d
  //done-
  'LD_Click_Enrol Now_Top Navigation': {
    label: 'View Schedules Click',
    name: 'Click_Enrol Now_Top Navigation',
    action: 'Clicked on view schedules',
    // country: '{{country_name}}',
    // city: '{{city_name}}',
    // timezone: '{user_timezone}',
    // url: '{{page_url}}',
    // message: '{message}',
    // course: '{{course_name}}',
    // category: '{{Course_category_name}}',
  },
  //done-
  'LD_Click_View Schedule_When1': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_When1',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  //done (workshop pending)---
  'LD_Click_Schedule Enrol Now_When1': {
    label: 'Schedule Enrol Now Click',
    name: 'Click_Schedule Enrol Now_When1',
    action: 'Clicked on Schedule Enrol Now',
    url: '{{page_url}}',
    message: '',
    WorkshopCode: '{{Workshop_Code}}',
    WorkshopStartDate: '{{Workshop_Startdate}}',
    WorkshopEndDate: '{{Workshop_enddate}}',
    LearningType: '{{Learning_type_name}}',
    Trainer: '{{Trainer_name}}',
    Currency: '{{Workshop_Currency_name}}',
    Price: '{{Workshop_Price}}',
  },
  //done
  'LD_Click_View Schedule_Online Classroom When3': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Online Classroom When3',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  //done
  'LD_Click_View Schedule_Classroom When3': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Classroom When3',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  //done
  'LD_Click_OSL Schedule Enrol Now_When3': {
    label: 'OSL Schedule Enrol Now Click',
    name: 'Click_OSL Schedule Enrol Now_When3',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
    Currency: '{{Workshop_Currency_name}}',
    Price: '{{Workshop_Price}}',
  },
  //done
  'LD_Click_View Schedule_When4': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_When4',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  //done
  'LD_Click_View Schedule_Upfront Payment When4': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Upfront Payment When4',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  // done
  'LD_Click_OSL Schedule Enrol Now_When4': {
    label: 'OSL Schedule Enrol Now Click',
    name: 'Click_OSL Schedule Enrol Now_When4',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
    Currency: '{{Workshop_Currency_name}}',
    Price: '{{Workshop_Price}}',
  },
  //done
  'LD_Click_View Schedule_When5': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_When5',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  //done
  'LD_Click_View Schedule_Pay After Placement When5': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Pay After Placement When5',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  //done
  'LD_Click_View Schedule_Monthly Payment When5': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Monthly Payment When5',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  //done
  'LD_Click_View Schedule_One Time Payment When5': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_One Time Payment When5',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  //done
  'LD_lick_View Schedule_Scholarship When5': {
    label: 'View Schedule Click',
    name: 'Click_View Schedule_Scholarship When5',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
  },
  //done
  'LD_Click_OSL Schedule Enrol Now_When5': {
    label: 'OSL Schedule Enrol Now Click',
    name: 'Click_OSL Schedule Enrol Now_When5',
    action: 'Clicked on view schedules',
    url: '{{page_url}}',
    message: '',
    Currency: '{{Workshop_Currency_name}}',
    Price: '{{Workshop_Price}}',
  },
  //done-
  'LD_Click_Become An Instructor_Instructor': {
    label: 'Become An Instructor Click',
    name: 'Click_Become An Instructor_Instructor',
    action: 'Click',
    url: '{{page_url}}',
  },
  //done
  'LD_Click_Read More Testimonials_Reviews': {
    label: 'Clicked on read more testimonials',
    name: 'Click_Read More Testimonials_Reviews',
    action: 'Clicked on read more testimonials',
    url: '{{page_url}}',
  },
  // FORMS (to be removed)
  // done-
  // 'Submit_Drop A Query_Footer Sticky': {
  //   label: 'Drop a Query Form Submit',
  //   name: 'Submit_Drop A Query_Footer Sticky',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Request For Call Back_Footer Sticky': {-
  //   label: 'Request a Call Back Form Submit',
  //   name: 'Submit_Request For Call Back_Footer Sticky',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Contact Learning Advisor_Banner1': {
  //   label: 'Course Advisor Form Submit',
  //   name: 'Submit_Contact Learning Advisor_Banner1',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Try For Free_Banner1': {
  //   label: 'Try For Free Form Submit',
  //   name: 'Submit_Try For Free_Banner1',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Contact Learning Advisor_Banner2': {
  //   label: 'Course Advisor Form Submit',
  //   name: 'Submit_Contact Learning Advisor_Banner2',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_OSL Try For Free_Banner2': {
  //   label: 'OSL Try For Free Form Submit',
  //   name: 'Submit_OSL Try For Free_Banner2',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  'Submit_Ever Webinar Register_Banner3': {
    label: 'Ever Webinar Register Form Submit',
    name: 'Submit_Ever Webinar Register_Banner3',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  // 'Submit_Contact Learning Advisor_Overview': {-
  //   label: 'Course Advisor Form Overview',
  //   name: 'Submit_Contact Learning Advisor_Overview',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Request A Quote_Sidebar_RHS': {-
  //   label: 'Request For A Custom Quote Form Submit',
  //   name: 'Submit_Request A Quote_Sidebar_RHS',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Get Custom Quote_Page Top Navigation': {-
  //   label: 'Request For A Custom Quote Form Submit',
  //   name: 'Submit_Get Custom Quote_Page Top Navigation',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Request To Download Syllabus_Curriculum': {
  //   label: 'Download Brochure Form Submit',
  //   name: 'Submit_Request To Download Syllabus_Curriculum',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Drop A Query_CTA1': {
  //   label: 'Drop a Query Form Submit',
  //   name: 'Submit_Drop A Query_CTA1',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Request To Download Syllabus_Page_CTA1': {
  //   label: 'Download Brochure Form Submit',
  //   name: 'Submit_Request To Download Syllabus_Page_CTA1',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Request A Custom Quote_CTA1': {
  //   label: 'Request For A Custom Quote Form Submit',
  //   name: 'Submit_Request A Custom Quote_CTA1',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Contact Learning Advisor_CTA1': {
  //   label: 'Course Advisor Form Submit',
  //   name: 'Submit_Contact Learning Advisor_CTA1',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Request For Call Back__CTA1': {
  //   label: 'Request a Call Back Form Submit',
  //   name: 'Submit_Request For Call Back__CTA1',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Request A Quotes_Enterprise': {
  //   label: 'Request For A Custom Quote Form Submit',
  //   name: 'Submit_Request A Quotes_Enterprise',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Contact Learning Advisor_KH Advantage': {
  //   label: 'Course Advisor Form Submit',
  //   name: 'Submit_Contact Learning Advisor_KH Advantage',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_OSL Try For Free_When1': {
  //   label: 'OSL Try For Free Form Submit',
  //   name: 'Submit_OSL Try For Free_When1',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // 'Submit_Request A Batch_When1': {
  //   label: 'Request A Batch Form Submit',
  //   name: 'Submit_Request A Batch_When1',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  //   learningType: '{{Learning_type_name}}',
  // },
  'Submit_Tuition Contact Learning Advisor_When2': {
    label: 'Tuition Contact Learning Advisor Form Submit',
    name: 'Submit_Tuition Contact Learning Advisor_When2',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
    learningType: '{{Learning_type_name}}',
  },
  'Submit_Tuition Scholarship_When2': {
    label: 'Tuition Scholarship Form Submit',
    name: 'Submit_Tuition Scholarship_When2',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
    learningType: '{{Learning_type_name}}',
  },
  Submit_Scholarship_When4: {
    label: 'Scholarship Form Submit',
    name: 'Submit_Scholarship_When4',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  'Submit_Pay When You Earn_When4': {
    label: 'Pay When You Earn Form Submit',
    name: 'Submit_Pay When You Earn_When4',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  'Submit_Certificate Programme_When4': {
    label: 'Certificate Programme Form Submit',
    name: 'Submit_Certificate Programme_When4',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  'Submit_Pay After Placement_When5': {
    label: 'Pay After Placement Form Submit',
    name: 'Submit_Pay After Placement_When5',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  'Submit_Monthly Payment_When5': {
    label: 'Monthly Payment Form Submit',
    name: 'Submit_Monthly Payment_When5',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  'Submit_One Time Payment_When5': {
    label: 'One Time Payment Form Submit',
    name: 'Submit_One Time Payment_When5',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  Submit_Scholarship_When5: {
    label: 'Scholarship Form Submit',
    name: 'Submit_Scholarship_When5',
    action: 'Submit',
    country: '{{country_name}}',
    city: '{{city_name}}',
    timezone: '{user_timezone}',
    url: '{{page_url}}',
    message: '{message}',
    course: '{{course_name}}',
    category: '{{Course_category_name}}',
  },
  // 'Submit_Unlock Preview_Curriculum': {
  //   label: 'Unlock Preview Form Submit',
  //   name: 'Submit_Unlock Preview_Curriculum',
  //   action: 'Submit',
  //   country: '{{country_name}}',
  //   city: '{{city_name}}',
  //   timezone: '{user_timezone}',
  //   url: '{{page_url}}',
  //   message: '{message}',
  //   course: '{{course_name}}',
  //   category: '{{Course_category_name}}',
  // },
  // Master class events
  'mc-click-register-rhs-top-sticky-nav': {
    label: 'Clicked on Enroll Now',
    name: 'MASTER_CLASS_ENROLL_NOW_BOOK_YOUR_SEAT',
    action: 'Clicked on Enroll Now',
  },
  'mc-click-register-rhs-top-sticky-nav-whatsapp-join': {
    label: 'Clicked on Join Whatsapp Group_Enroll Now - Button',
    name: 'MASTER_CLASS_ENROLL_NOW_BOOK_YOUR_SEAT',
    action: 'Clicked on Join Whatsapp Group_Enroll Now - Button',
  },
  'mc-click-register-rhs-top-sticky-nav-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_ENROLL_NOW_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'Yes',
  },
  'mc-submit-rhs-top-sticky-nav': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_ENROLL_NOW_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-click-choose-slot-rhs-top-sticky-nav': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_ENROLL_NOW_BOOK_YOUR_SEAT',
    action: 'Book Your Seat Now Form Submit',
  },
  'mc-click-copy-link-rhs-top-sticky-nav': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_ENROLL_NOW_BOOK_YOUR_SEAT',
    action: 'Book Your Seat Now Form Submit',
  },
  'mc-click-go-to-webinar-rhs-top-sticky-nav': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_ENROLL_NOW_BOOK_YOUR_SEAT',
    action: 'Book Your Seat Now Form Submit',
  },
  'mc-click-register-mc-banner-1': {
    label: 'Clicked on Book your Seat_Banner - Button',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Clicked on Book your Seat_Banner - Button',
  },
  'mc-click-register-mc-banner-1-whatsapp-join': {
    label: 'Clicked on Join Whatsapp Group_Banner - Button',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Clicked on Join Whatsapp Group_Banner - Button',
  },
  'mc-click-register-mc-banner-2': {
    label: 'Clicked on Book your Seat_Banner - Button',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Clicked on Book your Seat_Banner - Button',
  },
  'mc-click-register-mc-banner-1-sticky': {
    label: 'Clicked on Book your Seat_Mobile_Sticky - Button',
    name: 'MASTER_CLASS_MOBILE_STICKY_BOOK_YOUR_SEAT',
    action: 'Clicked on Book your Seat_Mobile_Sticky - Button',
  },
  'mc-click-register-mc-banner-2-sticky': {
    label: 'Clicked on Book your Seat_Mobile_Sticky - Button',
    name: 'MASTER_CLASS_MOBILE_STICKY_BOOK_YOUR_SEAT',
    action: 'Clicked on Book your Seat_Mobile_Sticky - Button',
  },
  'mc-click-register-mc-banner-2-whatsapp-join': {
    label: 'Clicked on Join Whatsapp Group_Banner - Button',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Clicked on Join Whatsapp Group_Banner - Button',
  },
  'mc-click-register-mc-banner-1-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'yes',
  },
  'mc-click-register-mc-banner-1-sticky-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_MOBILE_STICKY_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'Yes',
  },
  'mc-click-register-mc-banner-2-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'Yes',
  },
  'mc-click-register-mc-banner-2-sticky-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_MOBILE_STICKY_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'Yes',
  },
  'mc-submit-mc-banner-1': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-submit-mc-banner-1-sticky': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-submit-mc-banner-2': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-submit-mc-banner-2-sticky': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_MOBILE_STICKY_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-click-choose-slot-mc-banner-1': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Click',
  },
  'mc-click-choose-slot-mc-banner-2': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Click',
  },
  'mc-click-copy-link-mc-banner-1': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Click',
  },
  'mc-click-copy-link-mc-banner-2': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Click',
  },
  'mc-click-go-to-webinar-mc-banner-1': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Click',
  },
  'mc-click-go-to-webinar-mc-banner-2': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_BANNER_BOOK_YOUR_SEAT',
    action: 'Click',
  },
  'mc-click-register-book-session': {
    label: 'Clicked on Book a 1:1 session - Button',
    name: 'MASTER_CLASS_1:1_SESSION_BOOK_YOUR_SEAT',
    action: 'Clicked on Book a 1:1 session - Button',
  },
  'mc-submit-book-session-whatsapp-join': {
    label: 'Clicked on Join Whatsapp Group Book a 1:1 session - Button',
    name: 'MASTER_CLASS_1:1_SESSION_BOOK_YOUR_SEAT',
    action: 'Clicked on Join Whatsapp Group Book a 1:1 session - Button',
  },
  'mc-submit-book-session': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_1:1_SESSION_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-submit-book-session-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_1:1_SESSION_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'yes',
  },
  'mc-click-register-mc-program-how-does-1': {
    label: 'Clicked on Book your Seat_How Does - Button',
    name: 'MASTER_CLASS_HOW_DOES_BOOK_YOUR_SEAT',
    action: 'Clicked on Book your Seat_How Does - Button',
  },
  'mc-click-register-mc-program-how-does-1-whatsapp-join': {
    label: 'Clicked on Join Whatsapp Group How Does - Button',
    name: 'MASTER_CLASS_HOW_DOES_BOOK_YOUR_SEAT',
    action: 'Clicked on Join Whatsapp Group How Does - Button',
  },
  'mc-click-register-mc-program-how-does-1-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_HOW_DOES_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'Yes',
  },
  'mc-click-register-mc-program-how-does-1-answers': {
    label: 'Click_Register_Program How Does Answer',
    name: 'Click_Register_Program How Does Answer',
    action: 'Click',
  },
  'mc-click-choose-slot-mc-program-how-does-1': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_HOW_DOES_BOOK_YOUR_SEAT',
    action: 'Click',
  },
  'mc-click-copy-link-mc-program-how-does-1': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_HOW_DOES_BOOK_YOUR_SEAT',
    action: 'Click',
  },
  'mc-click-go-to-webinar-mc-program-how-does-1': {
    label: 'Book Your Seat Now Form Submit',
    name: 'MASTER_CLASS_HOW_DOES_BOOK_YOUR_SEAT',
    action: 'Click',
  },
  'mc-submit-mc-program-how-does-1': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_HOW_DOES_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-click-register-mc-reviews-1': {
    label: 'Clicked on Book your Seat_Reviews - Button',
    name: 'MASTER_CLASS_REVIEWS_BOOK_YOUR_SEAT',
    action: 'Clicked on Book your Seat_Reviews - Button',
  },
  'mc-click-register-mc-reviews-1-whatsapp-join': {
    label: 'Clicked on Join Whatsapp Group_Reviews - Button',
    name: 'MASTER_CLASS_REVIEWS_BOOK_YOUR_SEAT',
    action: 'Clicked on Join Whatsapp Group_Reviews - Button',
  },
  'mc-click-register-mc-reviews-1-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_REVIEWS_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'Yes',
  },
  'mc-submit-mc-reviews-1': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_REVIEWS_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-click-register-mc-workshop-flow-1': {
    label: 'Clicked on Book your Seat_Workshop Flow - Button',
    name: 'MASTER_CLASS_WORKSHOP_FLOW_BOOK_YOUR_SEAT',
    action: 'Clicked on Book your Seat_Workshop Flow - Button',
  },
  'mc-click-register-mc-workshop-flow-1-whatsapp-join': {
    label: 'Clicked on Join Whatsapp Group_Workshop Flow - Button',
    name: 'MASTER_CLASS_WORKSHOP_FLOW_BOOK_YOUR_SEAT',
    action: 'Clicked on Join Whatsapp Group_Workshop Flow - Button',
  },
  'mc-click-register-mc-workshop-flow-1-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_WORKSHOP_FLOW_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'Yes',
  },
  'mc-submit-mc-workshop-flow-1': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_WORKSHOP_FLOW_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-click-register-mc-faqs-1': {
    label: 'Clicked on Book your Seat_Faqs - Button',
    name: 'MASTER_CLASS_FAQS_BOOK_YOUR_SEAT',
    action: 'Clicked on Book your Seat_Faqs - Button',
  },
  'mc-click-register-mc-faqs-1-whatsapp-join': {
    label: 'Clicked on Join Whatsapp Group_Faqs - Button',
    name: 'MASTER_CLASS_FAQS_BOOK_YOUR_SEAT',
    action: 'Clicked on Join Whatsapp Group_Faqs - Button',
  },
  'mc-click-register-mc-faqs-1-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_FAQS_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'Yes',
  },
  'mc-submit-mc-faqs-1': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_FAQS_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'mc-click-register-mc-program-learn-1': {
    label: 'Clicked on Book your Seat_What We Learn - Button',
    name: 'MASTER_CLASS_WHAT_WE_LEARN_BOOK_YOUR_SEAT',
    action: 'Clicked on Book your Seat_What We Learn - Button',
  },
  'mc-click-register-mc-program-learn-1-whatsapp-join': {
    label: 'Clicked on Join Whatsapp Group_What We Learn - Button',
    name: 'MASTER_CLASS_WHAT_WE_LEARN_BOOK_YOUR_SEAT',
    action: 'Clicked on Join Whatsapp Group_What We Learn - Button',
  },
  'mc-click-register-mc-program-learn-1-otp': {
    label: 'Clicked on OTP Verification',
    name: 'MASTER_CLASS_WHAT_WE_LEARN_BOOK_YOUR_SEAT',
    action: 'Clicked on OTP Verification',
    isOTPVerified: 'Yes',
  },
  'mc-submit-mc-program-learn-1': {
    label: 'Submit on Book Your Seat',
    name: 'MASTER_CLASS_WHAT_WE_LEARN_BOOK_YOUR_SEAT',
    action: 'Submit on Book Your Seat',
  },
  'ga4-click-overview-4-page-top-navigation': {
    label: 'Overview',
    name: 'Click_Overview',
    action: 'Click',
  },
  'ga4-click-prerequisite-1-page-top-navigation': {
    label: 'Prerequisites',
    name: 'Click_Prerequisites',
    action: 'Click',
  },
  'ga4-click-knowledgehut-experience-2-page-top-navigation': {
    label: 'Why KH',
    name: 'Click_Why KH',
    action: 'Click',
  },
  'ga4-click-curriculum-1-page-top-navigation': {
    label: 'Curriculum',
    name: 'Click_Curriculum',
    action: 'Click',
  },
  'ga4-click-career-path-1-page-top-navigation': {
    label: 'Career Path',
    name: 'Click_Career Path',
    action: 'Click',
  },  
  'ga4-click-faq-1-page-top-navigation': {
    label: 'FAQs',
    name: 'Click_FAQs',
    action: 'Click',
  }, 
  'ga4-click-for-enterprise-page-top-navigation': {
    label: 'For Enterprise',
    name: 'Click_For Enterprise',
    action: 'Click',
  },
  'ga4-click-download-syllabus-page-top-navigation': {
    label: 'Download Syllabus',
    name: 'Click_Download Syllabus',
    action: 'Click',
  },
  'ga4-click-curriculum-expand': {
    label: 'Curriculum_Expand',
    name: 'Click_Curriculum',
    action: 'Click',
  },
  'ga4-click-view-schedule-and-pricing': {
    label: 'View Schedule and Pricing',
    name: 'Click_View Schedule',
    action: 'Click',
  },
  'ga4-click-talk-to-scrum-career-path': {
    label: 'Talk to Scrum Expert',
    name: 'Click_Talk to Scrum',
    action: 'Click',
  },
  'ga4-click-show-discount-career-path': {
    label: 'Show Discount',
    name: 'Click_Show Discount',
    action: 'Click',
  },
  'click-enroll-your-group-career-path': {
    label: 'Enroll your Group Now',
    name: 'Click_Enroll your Group',
    action: 'Click',
  },
  'ga4-click-faq-expand': {
    label: 'FAQs_Expand',
    name: 'Click_FAQs_Expand_FAQs',
    action: 'Click',
  },
  'ga4-click-read-more-testimonals-expand': {
    label: 'Read More Testimonials_Expand',
    name: 'Read More Testimonials',
    action: 'Click',
  },
  'ga4-click-view-online-schedules-sticky-nav-rhs': {
    label: 'View Online Schedules',
    name: 'Click_View Online Schedules',
    action: 'Click',
  },
  'ga4-click-request-quote-corporate-trainig-sticky-nav-rhs': {
    label: 'Request Quote_Corporate Training',
    name: 'Click_Request Quote_Corporate',
    action: 'Click',
  },
  'submit-for-enterprise-page-top-navigation': {
    label: 'For Enterprise',
    name: 'Submit_For Enterprise',
    action: 'Submit',
  },
};
