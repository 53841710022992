import Link from 'next/link';
import React, { useState } from 'react';
import tw from 'twin.macro';
import { FooterAccordionArrow } from '../Icon';
import { topCategories, topCourses } from './data';

type ServiceItem = {
  name: string;
  href: string;
  rel?: number;
};

const ServiceWrapper = tw.section`md:border-b md:border-[#222222] pb-2 md:pb-0  md:py-8`;

const DesktopServiceItem: React.FC<{
  name: string;
  count?: number;
  service: ServiceItem[];
}> = ({ name, service, count }) => {
  return (
    <div className="">
      <p tw="text-[16px] text-[#cccccc] font-semibold h-11 xl:h-auto rounded-[16px] ">{name}</p>
      <ul tw="pt-[23px] grid grid-cols-2">
        
          {service.slice(0, count)?.map(({ name, href, rel }, idx) => (
            <li key={idx} tw="text-[13px] text-[#cccccc] h-10 leading-7 xl:h-auto">
              <a href={href} title={name} target="_blank" rel="noreferrer noopener">
                {name}
              </a>
            </li>
          ))}
        
          {service.slice(count)?.map(({ name, href, rel }, idx) => (
            <li key={idx} tw="text-[13px] text-[#cccccc] h-10 leading-7 xl:h-auto">
              <a href={href} target="_blank" title={name} rel="noreferrer noopener">
                {name}
              </a>
            </li>
          ))}
        
      </ul>
    </div>
  );
};

const MobileServiceItem: React.FC<{
  isActive: boolean;
  name: string;
  service: ServiceItem[];
  onClick: (name: string) => void;
}> = ({ isActive, name, service, onClick }) => {
  return (
    <div tw="text-[#ebebeb] border-b border-[#222222] ">
      <div tw="relative block w-full text-left py-4 text-sm leading-[14px] font-semibold" onClick={() => onClick(name)}>
        {name}
        <span tw="absolute right-0 transform">
          <FooterAccordionArrow
            className={`icon-down-arrow-white blog-icons inline-block transition-transform duration-300 rotate-180 ${
              isActive && 'rotate-0'
            }`}
          />
        </span>
      </div>
      <div tw="transition-all duration-300 overflow-hidden max-h-0" css={[isActive && tw`max-h-96`]}>
        <ul tw="px-3 pb-4">
          {service?.map(({ name, href, rel }, idx) => (
            <li key={idx} tw="text-xs leading-8">
              <a href={href} target="_blank" title={name} rel="noreferrer noopener">
                {name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SectionServices2: React.FC = () => {
  const [serviceActive, setService] = useState<string>('');

  const onServiceClick = (name: string) => {
    setService((service) => {
      if (service === name) {
        return '';
      }

      return name;
    });
  };
  return (
    <ServiceWrapper>
      <div tw="md:grid md:grid-cols-2 md:visible invisible hidden ">
        <DesktopServiceItem name="Top Categories" service={topCategories} count={7} />
        <DesktopServiceItem name="Top Courses" service={topCourses} count={6} />
      </div>
    </ServiceWrapper>
  );
};

export default SectionServices2;
