import tw from 'twin.macro';

export const HeaderSprite = tw.i`bg-header_sprite bg-no-repeat`;

export const CartBlack = tw(HeaderSprite)`bg-position-header-cart-black w-[17px] h-[16px] inline-block`;

export const SearchIcon = tw(HeaderSprite)`bg-position-search w-[20px] h-[20px] inline-block`;

export const SearchCloseIcon = tw(HeaderSprite)`bg-position-search-close w-[14px] h-[14px] inline-block align-top`;

export const MenuItemIcon = tw(HeaderSprite)`bg-position-menuitem-down-arrow w-[6px] h-1 inline-block`;

export const MegaMenuIcon = tw(HeaderSprite)`bg-position-megamenu-down-arrow w-3 h-2 inline-block`;

export const HeaderMenuIcon = tw(HeaderSprite)`bg-position-header-siderbar-menu-icon w-[18px] h-[12px] inline-block`;

export const FooterAccordionArrow = tw(HeaderSprite)`bg-position-footer-accordion-arrow inline-block w-[14px] h-[9px]`;

export const BlackArrowRight = tw(HeaderSprite)`bg-position-arrow-right w-[9px] h-[14px] inline-block`;

export const CallBtn = tw(HeaderSprite)`bg-position-call-btn w-[14px] h-[14px] inline-block`;
