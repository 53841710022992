import React, { useEffect } from "react";

declare const window: any;
const MegaMenuShadowDOM: React.FC = () => {
  useEffect(() => {
    window.shadowRoot = document
      .querySelector("#my-shadow-dom-component")
      ?.attachShadow({ mode: "open" });

    if (window.shadowRoot) {
      const link = document.createElement("link");
      link.rel = "stylesheet";

      link.href = "/shadow-styles.css";
      window.shadowRoot.appendChild(link);

      const link2 = document.createElement("link");
      link2.rel = "stylesheet";
      link2.href =
        "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap";
      window.shadowRoot.appendChild(link2);

      const script = document.createElement("script");
      script.src = "/shadow-script.js";
      window.shadowRoot.appendChild(script);

      const dom = document.createElement("div");
      dom.id = "mega-menu-root-2";
      window.shadowRoot.appendChild(dom);
    }
  }, []);

  return <div id="my-shadow-dom-component"></div>;
};

export default MegaMenuShadowDOM;
