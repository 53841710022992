// getFormatDateForSnippet
export const getFormatDateForSnippet = (startDate: string, endDate: string, bYear: boolean) => {
  let startDateF = new Date(startDate);
  let endDateF = new Date(endDate);
  const currenctDate = new Date();
  let strDate = '';

  // Check same year
  if (startDateF.getUTCFullYear() == endDateF.getUTCFullYear()) {
    // Check same month
    if (startDateF.getUTCMonth() == endDateF.getUTCMonth()) {
      // Check same day
      if (startDateF.getUTCDate() == endDateF.getUTCDate()) {
        // Check year is equal to current year
        strDate =
          startDateF.getUTCFullYear() == currenctDate.getUTCFullYear() && !bYear
            ? startDateF.toLocaleDateString('en-US', {
                month: 'short',
              }) +
              ' ' +
              startDateF.getUTCDate()
            : startDateF.toLocaleDateString('en-US', {
                month: 'short',
              }) +
              ' ' +
              ('0' + startDateF.getUTCDate()).slice(-2) +
              ', ' +
              startDateF.getUTCFullYear();
      } // Days are not equal
      else {
        // Check year is equal to current year
        strDate =
          startDateF.getUTCFullYear() == currenctDate.getUTCFullYear() && !bYear
            ? startDateF.toLocaleDateString('en-US', { month: 'short' }) +
              ' ' +
              ('0' + startDateF.getUTCDate()).slice(-2) +
              ' - ' +
              endDateF.toLocaleDateString('en-US', { month: 'short' }) +
              ' ' +
              ('0' + endDateF.getUTCDate()).slice(-2)
            : startDateF.toLocaleDateString('en-US', { month: 'short' }) +
              ' ' +
              ('0' + startDateF.getUTCDate()).slice(-2) +
              ' - ' +
              endDateF.toLocaleDateString('en-US', { month: 'short' }) +
              ' ' +
              ('0' + endDateF.getUTCDate()).slice(-2) +
              ', ' +
              endDateF.getUTCFullYear();
      }
    } // Months are not equal
    else {
      // Check year is equal to current year
      strDate =
        startDateF.getUTCFullYear() == currenctDate.getUTCFullYear() && !bYear
          ? startDateF.toLocaleDateString('en-US', { month: 'short' }) +
            ' ' +
            ('0' + startDateF.getUTCDate()).slice(-2) +
            ' - ' +
            endDateF.toLocaleDateString('en-US', { month: 'short' }) +
            ' ' +
            ('0' + endDateF.getUTCDate()).slice(-2)
          : startDateF.toLocaleDateString('en-US', { month: 'short' }) +
            ' ' +
            ('0' + startDateF.getUTCDate()).slice(-2) +
            ' - ' +
            endDateF.toLocaleDateString('en-US', { month: 'short' }) +
            ' ' +
            ('0' + endDateF.getUTCDate()).slice(-2) +
            ' ' +
            endDateF.getUTCFullYear();
    }
  } // Years are not equal
  else {
    strDate =
      startDateF.toLocaleDateString('en-US', { month: 'short' }) +
      ' ' +
      ('0' + startDateF.getUTCDate()).slice(-2) +
      ', ' +
      startDateF.getUTCFullYear() +
      ' - ' +
      endDateF.toLocaleDateString('en-US', { month: 'short' }) +
      ' ' +
      ('0' + endDateF.getUTCDate()).slice(-2) +
      ', ' +
      endDateF.getUTCFullYear();
  }

  return strDate;
};

// stripTags
export const stripTags = (str: string) => {
  return str.replace(/(<([^>]+)>)/gi, '');
};

// randomValue
export const randomValue = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// getFileSize
export const getFileSize = (url) => {
  let fileSize;
  const http = new XMLHttpRequest();
  http.open('HEAD', url, false); // false = Synchronous

  http.send(null); // it will stop here until this http request is complete

  if (http.status === 200) {
    fileSize = http.getResponseHeader('content-length');
  } else {
    fileSize = 0;
  }

  return fileSize;
};

// currencyFormatter
export const currencyFormatter = (props: { locale: string; currency: string; price: number }) => {
  let { locale, currency, price } = props;
  try {
    var formattedPrice = new Intl.NumberFormat(locale, { currency: currency }).format(price);
  } catch (e) {
    console.error('currency Formateer issue' + e);
    return price;
  }
  return formattedPrice;
};

// currencyFormatterWithSymbol
export const currencyFormatterWithSymbol = (props: { locale: string; currency: string; price: number }) => {
  let { locale, currency, price } = props;
  try {
    var formattedPrice = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
    }).format(price);
  } catch (e) {
    console.error('currency Formateer issue' + e);
    return price;
  }
  return formattedPrice;
};

// maskPhoneNumber
export const maskPhoneNumber = (phoneNumber: string) => {
  return (
    phoneNumber.substring(0, 2) +
    Array(phoneNumber.length - 4).join('*') +
    phoneNumber.substring(phoneNumber.length - 2)
  );
};
