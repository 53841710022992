import { stripTags } from '../../lib/common/common';
import { tracker } from './tracker';

const triggerMoengageCourseView = ({ coureName, categoryName }) => {
  let startEvent = setInterval(() => {
    if (window['Moengage'] === undefined) return;
    tracker({
      trackerType: 'moengage',
      eventAction: 'Course Viewed',
      data: {
        course: stripTags(coureName),
        category: stripTags(categoryName),
      },
    });

    clearInterval(startEvent);
  }, 100);
};

export default triggerMoengageCourseView;
