import { ChevronDownIcon } from '@heroicons/react/solid';
import { trackGa4Events } from '../../../lib/events/ga4/tracker';
import React from 'react';

type Props = {
  leftBorder?: boolean;
  leftIcon?: any;
  title: string;
  icon?: any;
  icon2?: any;
  showBody: any;
  changeBodyStatus: any;
  sticky?: boolean;
  children: any;
  isShowNewTag?: boolean;
  ga4EventKey?: any;
};

const Accordion: React.FC<Props> = ({
  title,
  icon,
  icon2,
  leftIcon,
  leftBorder,
  showBody,
  changeBodyStatus,
  sticky,
  children,
  isShowNewTag,
  ga4EventKey,
}) => {
  // console.log(showBody + " - " + title)

  return (
    <div className={`${leftBorder && `border-l-2 ${showBody === title ? 'border-[#df377f]' : 'border-transparent'}`}`}>
      {/* Accordion Heading */}
      <div
        className={`flex justify-between items-center p-4 h-[52px] border-b border-custom-gray-900 cursor-pointer ${
          sticky && 'sticky -top-[1px] bg-white z-10'
        }`}
        onClick={() => {changeBodyStatus(title); trackGa4Events(ga4EventKey)}}
      >
        <div className={`flex items-center text-sm relative font-semibold leading-[19px]`}>
          {leftIcon && <div className="mr-1">{leftIcon}</div>}
          <div>{title}</div>
          {isShowNewTag && (
            <span className="relative ml-1 h-[15px] py-0.5 px-1.5 text-[8px] leading-[11px] bg-[#544fbc] rounded-[44px] align-middle text-white">New</span>
          )}
        </div>
        <div className={`${showBody === title && ''} transition-all duration-300 w-5`}>
          {(showBody === title && icon2) || icon || (
            <i
              className={`ml-[4px] sidebar-arrow-down-icon sidebar-icon inline-block transition-transform duration-300 ${
                showBody === title && '   sidebar-arrow-up-pink-icon sidebar-icon'
              }`}
            />
          )}
        </div>
      </div>
      
      {/* TODO (change max-h) */}
      {/* Accordion Body */}
      <div
        className={`bg-gray-100 transition-all p-0  duration-500 overflow-hidden max-h-0 ${
          showBody === title && 'max-h-[10000px]'
        }`}
      >
        {children}
      </div>
    </div>
  );
};
export default Accordion;
