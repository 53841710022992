import { gql } from '@apollo/client';

export const GET_COURSE_DATA = gql`
  query getCourseData($filter: CourseFilter, $paging: CursorPaging, $sorting: [CourseSort!]) {
    courses(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id: legacy_id
          code
          name
          short_name
          cover_image
          share_image
          accreditation_logo
          display_icon
          brochure
          video_youtube_embed
          video_image
          rating
          key_point_1
          key_point_2
          box_label
          duration
          no_of_students
          no_of_reviews
          no_of_views
          no_of_shares
          no_of_countries
          no_of_corporates
          description
          short_description
          other_description
          tag_line
          slug
          status
          accreditation_link
          accreditation_name
          category {
            id
            name
            slug
            share_image
            secondary_slug
          }
          cst_icon_class
          osl_description
          is_program
          regular_track_description
          fast_track_description
          is_ssg_approved
          is_utap_approved
          utap_funding_content
          i_cart_subsidy_content
          is_ibf_approved
          ibf_link
          is_sfc_approved
          course_subsidy_heading
          schedule_subsidy_heading
          course_subsidy_content
          schedule_subsidy_content
          osl_layout_status
          course_class,
          accreditation_icon_class,
          more_accreditation_icon_classes,
          money_back_content,
          money_back_heading
        }
      }
    }
  }
`;

export const GET_RELATED_COURSE_DATA = gql`
  query getCourseData($filter: CourseFilter, $paging: CursorPaging, $sorting: [CourseSort!]) {
    courses(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id
          code
          name
          shortName:short_name
          shortDescription:short_description
          slug
          box_label,
          key_point_1,
          key_point_2,
          duration,
          rating,
          accreditation_icon_class,
          course_class,
          category {
            id
            name
            slug
            share_image
            secondary_slug
          }
          status
        }
      }
    }
  }
`;

export const GET_COURSE_SEARCH_DATA = gql`
  query getCourseSearchData($filter: CourseFilter, $paging: CursorPaging, $sorting: [CourseSort!]) {
    courses(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id
          name
          slug
          category {
            id
            name
            slug
            share_image
            secondary_slug
          }
        }
      }
    }
  }
`;

export const GET_COURSE_LIST_DATA = gql`
  query getCourseData($filter: CourseFilter, $paging: CursorPaging, $sorting: [CourseSort!]) {
    courses(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id: legacy_id
          code
          name
          trending
        }
      }
    }
  }
`;

export const GET_COURSE_LEARNING_TYPES_DATA = gql`
  query getCourseData($filter: CourseFilter, $paging: CursorPaging, $sorting: [CourseSort!]) {
    courses(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          learning_types {
            id: legacy_id
            code
            name
            title
            description
            slug
          }
        }
      }
    }
  }
`;
