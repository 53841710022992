import { getUtmParams } from '../../lib/common/utm';
import { formSubmitHandler } from '../../components/layout-a/forms/common/formSubmitHandler';
import { tracker } from './tracker';
import { json } from 'stream/consumers';

const zopimChat = () => {
  const getUtmParamsData = getUtmParams();

  let nodePrams = [];
  for (let key in getUtmParamsData) {
    if (getUtmParamsData[key] !== '') {
      nodePrams.push({ name: key, value: getUtmParamsData[key] });
    }
  }

  // addZopimChatLead
  const addZopimChatLead = async (data) => {
    // extraData
    const extraData = {
      leadType: 'zopimLead',
      static_lead_type: 'ZOPIM_LEAD',
      action: 'Zopim Chat Initiated',
      name: 'Zopim Chat',
      label: 'Zopim Chat',
    };

    // tracker
    tracker({
      trackerType: 'all',
      eventAction: 'Zopim Chat Initiated',
      eventName: extraData.name,
      eventLabel: extraData.label,
      data: { ...data, ...extraData },
    });

    const leadData = { ...data, ...extraData };

    const response = await formSubmitHandler(leadData, extraData.leadType);
  };

  let waitForZopim = setInterval(function () {
    if (window['$zopim'] === undefined || window['$zopim'].livechat === undefined) {
      return;
    }
    window['$zopim'].livechat.setOnChatStart(function () {
      // appendNotes
      window['$zopim'].livechat.appendNotes(JSON.stringify(nodePrams));

      // window['$zopim'].livechat.setName("test1");
      // window['$zopim'].livechat.setEmail("test1@gmail.com");
      // window['$zopim'].livechat.setPhone("32424324");

      const data = {
        name: window['$zopim'].livechat.getName(),
        email: window['$zopim'].livechat.getEmail(),
        phone: window['$zopim'].livechat.getPhone(),
      };

      addZopimChatLead(data);
    });
    clearInterval(waitForZopim);
  }, 100);
};

export default zopimChat;
