declare const window: any;

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

export const pageview = (url: any) => {
  window.gtag('event', 'page_view', {
    page_path: url,
  });
};

export const event = ({ action, name, label, value }: { action: any; name: any; label: any; value?: any }) => {
  try {
    window.gtag('event', action, {
      event_category: name,
      event_label: label,
      value: value,
    });
  } catch (e) {
    console.log({ gtagError: e });
  }
};
