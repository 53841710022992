import { PlusSmIcon, MinusSmIcon } from '@heroicons/react/outline';
import Accordion from '../Accordion';
import { MenuItemsData } from '../MenuItems/Interface';
import Link from 'next/link';
import { SyntheticEvent, useEffect, useState } from 'react';
import React from 'react';
import { useRouter } from 'next/router';
import menuDetails from '../MegaMenu/Data';
import { BootcampData, EnterpriseData, HelpData, ResourcesData } from '../MenuItems/Data';
import { CartBlack, SearchCloseIcon } from '../Icon';
import Button from '../../../components/layout-a/components/common/Button';
import { CartIcon } from 'components/layout-a/components/Icon';
import { ChevronDownIcon } from '@heroicons/react/solid';
import HeaderSidebarContactUs from './HeaderSidebarContactUs';
import { trackGa4Events } from '../../../lib/events/ga4/tracker';

const HeaderSideBar: React.FC<any> = ({ sideBar, side, closeAllSideBars }) => {
  const router = useRouter();

  const [currentPath, setCurrentPath] = useState<string>(router.asPath);

  // Accordion style
  const AccordionBodyStyle = `h-[49px]  flex items-center  bg-gray-100 text-[#231f20]  text-[13px] font-semibold`;

  // Accordion body
  const [showBody, setShowBody] = useState<string>('');

  // Accordion Course body
  const [showCourses, setShowCourses] = useState<string>('');

  // Sidebar accordions (Courses, More, Help) open/close
  const changeBodyStatus: any = (title: string) => {
    setShowBody(showBody === title ? '' : title);
  };

  // Courses accordions open/close
  const changeCourseStatus: any = (title: string) => {
    setShowCourses(showCourses === title ? '' : title);
  };

  // State for Subscribe modal
  const [open, setOpen] = useState(false);

  // Closes Sidebar
  const closeSideBar = () => {
    closeAllSideBars();
  };

  const handleClose = (e: any) => {
    if (e.target?.id === 'overlay') {
      closeSideBar();
    }
  };

  // Disabling / Enabling Scroll Overflow for <body>
  useEffect(() => {
    document.body.style.overflow = sideBar ? 'hidden' : 'auto';
  }, [sideBar]);

  return (
    <div
      className={`fixed bg-white w-screen inset-0 
        ${sideBar === null ? 'opacity-0' : 'opacity-100'}
        ${sideBar && side === 'left' ? 'translate-x-0' : '-translate-x-full'}
        transform transition ease-in-out duration-500 bg-transparent z-[999] `}
      id="overlay"
      onClick={(e: SyntheticEvent) => handleClose(e)}
    >
      <div
        className={`fixed bg-white inset-0 ${
          sideBar && side === 'left' ? 'translate-x-0' : '-translate-x-full'
        } transform transition ease-in-out duration-500 bg-white z-50`}
      >
        {/* <BlogSubscribeModal open={open} setOpen={setOpen} /> */}
        {sideBar && side === 'left' && (
          <>
            {/* Heading with Close icon */}
            <div className="sticky top-0 left-0 flex justify-between items-center h-[51px] w-full pt-4 px-4 pb-[15px] border-b border-custom-gray-900 bg-[#f5f5f5]">
              <div className="text-sm font-bold leading-[19px] text-black">Menu</div>
              <div className="pr-[7px] cursor-pointer" onClick={closeSideBar}>
                <SearchCloseIcon className="" />
              </div>
            </div>

            {/* Accordions & Links for Megamenu & Menuitems */}
            <div className="fixed flex flex-col justify-between top-[51px] left-0 right-0 bottom-0">
              <div className="bg-white z-50 h-5/6  overflow-auto overflow-y-auto customized-scrollbar">
                {/* Accordions for MegaMenu */}
                <Accordion
                  title="Courses"
                  showBody={showBody}
                  changeBodyStatus={changeBodyStatus}
                  sticky={showBody === 'Courses' ? true : false}
                >
                  {/* Accordions for Courses in MegaMenu */}
                  {menuDetails?.map((categoryItem: any, index: number) => (
                    <Accordion
                      title={categoryItem.name}
                      icon={<PlusSmIcon />}
                      icon2={<MinusSmIcon />}
                      leftBorder={true}
                      key={index}
                      showBody={showCourses}
                      changeBodyStatus={changeCourseStatus}
                      ga4EventKey = {`mega_menu_click_menu_items{${categoryItem.name}}`}
                    >
                      {categoryItem.courses?.map((item: any, index: number) => (
                        <div className={AccordionBodyStyle} key={index} onClick={closeSideBar}>
                          <a
                            href={item.courseUrl}
                            className="w-full border-b border-gray-200 py-[13px] mx-5 text-[13px] text-[#231f20] font-regular"
                            dangerouslySetInnerHTML={{ __html: item.courseName }}
                            onClick={() => {
                              trackGa4Events(`mega_menu_click_course_menu_items{${item.courseName}}`, {parameters : {category : categoryItem.name }});
                           }}
                          ></a>
                        </div>
                      ))}
                      {categoryItem.courses.length >= 10 && (
                        <div className={AccordionBodyStyle} key={index}>
                          <a className="w-full px-5" href={categoryItem.link}>
                            <div className="flex items-center justify-between" onClick={closeSideBar}>
                              <div className="text-[#df377f] text-[13px] font-semibold" onClick={() => { trackGa4Events('mega_menu_click_view_all_courses')}}>View All Courses</div>
                              <div>
                                <i className="inline-block icon-right-arrow-pink blog-icons"></i>
                              </div>
                            </div>
                          </a>
                        </div>
                      )}
                    </Accordion>
                  ))}
                </Accordion>

                <Accordion
                  title="Bootcamps"
                  showBody={showBody}
                  changeBodyStatus={changeBodyStatus}
                  isShowNewTag={true}
                >
                  {BootcampData?.map(({ title, ref, data }: MenuItemsData, index: number) =>
                    data ? (
                      <Accordion
                        title={title}
                        icon={<PlusSmIcon />}
                        icon2={<MinusSmIcon />}
                        leftBorder={true}
                        key={index}
                        showBody={showCourses}
                        changeBodyStatus={changeCourseStatus}
                      >
                        {data?.map(({ title, ref }: MenuItemsData, index: number) => (
                          <div className={AccordionBodyStyle} key={index} onClick={closeSideBar}>
                            <a
                              href={ref}
                              className="w-full py-[13px] mx-5 text-[13px] text-[#231f20]  font-normal"
                              dangerouslySetInnerHTML={{ __html: title }}
                            ></a>
                          </div>
                        ))}
                      </Accordion>
                    ) : (
                      <div className={AccordionBodyStyle} key={index} onClick={closeSideBar}>
                        <a
                          href={ref}
                          className=" border-b border-gray-300 w-full p-4 text-sm"
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></a>
                      </div>
                    )
                  )}
                </Accordion>

                {/* Accordion for Enterprise Menu item */}
                <Accordion title="Enterprise" showBody={showBody} changeBodyStatus={changeBodyStatus}>
                  {EnterpriseData?.map(({ title, ref, data }: MenuItemsData, index: number) =>
                    data ? (
                      <Accordion
                        title={title}
                        icon={<PlusSmIcon />}
                        icon2={<MinusSmIcon />}
                        leftBorder={true}
                        key={index}
                        showBody={showCourses}
                        changeBodyStatus={changeCourseStatus}
                      >
                        {data?.map(({ title, ref }: MenuItemsData, index: number) => (
                          <div className={AccordionBodyStyle} key={index} onClick={closeSideBar}>
                            <a
                              href={ref}
                              className="w-full py-[13px] mx-5 text-[13px] text-[#231f20]  font-normal"
                              dangerouslySetInnerHTML={{ __html: title }}
                            ></a>
                          </div>
                        ))}
                      </Accordion>
                    ) : (
                      <div className={AccordionBodyStyle} key={index} onClick={closeSideBar}>
                        <a
                          href={ref}
                          className="border-b border-gray-300 w-full p-4 text-sm"
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></a>
                      </div>
                    )
                  )}
                </Accordion>

                {/* Accordion for Resources Menu item */}
                <Accordion title="Resources" showBody={showBody} changeBodyStatus={changeBodyStatus}>
                  {ResourcesData?.map(({ title, ref, data }: MenuItemsData, index: number) =>
                    data ? (
                      <Accordion
                        title={title}
                        icon={<PlusSmIcon />}
                        icon2={<MinusSmIcon />}
                        leftBorder={true}
                        key={index}
                        showBody={showCourses}
                        changeBodyStatus={changeCourseStatus}
                      >
                        {data?.map(({ title, ref }: MenuItemsData, index: number) => (
                          <div className={AccordionBodyStyle} key={index} onClick={closeSideBar}>
                            <a
                              href={ref}
                              className="w-full py-[13px] mx-5 text-[13px] text-[#231f20]  font-normal"
                              dangerouslySetInnerHTML={{ __html: title }}
                            ></a>
                          </div>
                        ))}
                      </Accordion>
                    ) : (
                      <div className={AccordionBodyStyle} key={index} onClick={closeSideBar}>
                        <a
                          href={ref}
                          className=" border-b border-gray-300 w-full p-4 text-sm"
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></a>
                      </div>
                    )
                  )}
                </Accordion>

                {/* Accordion for Help Menu item */}
                <Accordion title="Help" showBody={showBody} changeBodyStatus={changeBodyStatus}>
                  {HelpData?.map(({ title, ref ,noFollow }: MenuItemsData, index: number) => (
                    <div className={AccordionBodyStyle} key={index} onClick={closeSideBar}>
                      <a
                      rel={noFollow && 'nofollow'}
                        href={ref}
                        className="w-full p-4 border-b border-gray-300  text-sm"
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></a>
                    </div>
                  ))}
                </Accordion>

                {/* Cart link */}
                <div
                  className={`flex items-center border-b border-gray-200 bg-white text-[#231f20]  text-[13px] font-semibold text-sm leading-5 `}
                  onClick={closeSideBar}
                >
                  <a href={'/cart'} className="w-full p-4 flex items-center text-sm">
                    <CartIcon />
                  </a>
                </div>
              </div>

              {/* Subscribe & SignIn buttons */}
              <HeaderSidebarContactUs />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderSideBar;
