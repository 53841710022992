export const companyService = [
  { name: 'About Us', href: '/about-us', targetBlank: true },
  { name: 'Careers', href: '/career', rel: 1, noFollow: true },
  { name: 'Accreditation', href: '/accreditation' },
  { name: 'Customer Speak', href: '/customer-speak' },
  { name: 'Media', href: '/media' },
  { name: 'Contact Us', href: '/contact-us' },
  { name: 'Grievance Redressal', href: '/complaint-forum' },
];
export const offeringService = [
  { name: 'Live virtual (online)', href: '/courses' },
  { name: 'Classroom', href: '/courses' },
  { name: 'Agile Services', href: '/agile-services', rel: 1, noFollow: true },
  { name: 'Refer & Earn', href: '/refer-earn', noFollow: true },
  { name: 'Corporate Training', href: '/corporate-training' },
];

export const resourceService = [
  { name: 'Course Info', href: '/info' },
  { name: 'Tutorials', href: '/tutorials', dataTrackerId: 'click_tutorials' },
  { name: 'Blog', href: '/blog', dataTrackerId: 'click_blogs' },
  { name: 'Interview Questions', href: '/interview-questions', dataTrackerId: 'click_interview_questions' },
  { name: 'Practice Tests', href: '/practice-tests', dataTrackerId: 'click_practice_test' },
  { name: 'Master Classes', href: '/master-classes' },
];

export const partnerService = [
  { name: 'Become an Instructor', href: '/become-an-instructor', rel: 1, noFollow: true },
  { name: 'Hire from Us', href: '/hire-from-us-engineers' },
  { name: 'Become a Training Partner', href: '/training-partner' },
  { name: 'Affiliates', href: '/affiliate-program' },
];

export const supportService = [
  { name: 'FAQs', href: '/faqs', dataTrackerId: 'click_faqs', rel: 1 },
  { name: 'Terms & Conditions', href: '/terms-conditions', rel: 1, noFollow: true },
  { name: 'Privacy Policy & Disclaimer', href: '/privacy-policy', rel: 1, noFollow: true },
  { name: 'Cancellation & Refund Policy', href: '/refund-policy', rel: 1, noFollow: true },
  { name: 'Site map', href: '/html-sitemap' },
];

export const topCategories = [
  { name: 'Agile Management Courses', href: '/agile-management' },
  { name: 'Cloud Computing Courses', href: '/cloud-computing-courses' },
  { name: 'Project Management Courses', href: '/project-management-certifications' },
  { name: 'Devops Courses', href: '/devops-courses' },
  { name: 'IT Service Management Courses', href: '/itsm-certifications' },
  { name: 'Business Management Courses', href: '/business-management-courses' },
  { name: 'Programming Courses', href: '/programming-certification' },
  { name: 'Data Science Courses', href: '/data-science-courses' },
  { name: 'Web Development Courses', href: '/web-development-courses' },
  { name: 'BI and Visualization Courses', href: '/business-intelligence-and-visualization' },
  { name: 'Mobile App Development Courses', href: '/mobile-app-development-courses' },
  { name: 'Quality Management Courses', href: '/quality-management-courses' },
  { name: 'Six Sigma Courses', href: '/six-sigma-certifications' },
  { name: 'Kanban Courses', href: '/kanban-certifications' },
];
export const topCourses = [
  { name: 'CSM Certification', href: '/agile-management/csm-certification-training' },
  {
    name: 'PRINCE2 Certification',
    href: '/project-management/prince2-foundation-and-practitioner-certification-training',
  },
  { name: 'CSPO Certification', href: '/agile-management/cspo-certification-training' },
  { name: 'Devops Foundation Certification', href: '/devops/devops-foundation-certification-training' },
  { name: 'Leading SAFe 5.1 Certification', href: '/agile-management/leading-safe-certification-training' },
  {
    name: 'Data Science with Python Certification',
    href: '/data-science/data-science-with-python-certification-training',
  },
  { name: 'PSM Certification', href: '/agile-management/psm-certification-training' },
  { name: 'Full-Stack Development Bootcamp', href: '/web-development/fullstack-development-bootcamp-training' },
  { name: 'PMP Certification', href: '/project-management/pmp-certification-training' },
  { name: 'Front-End Development Bootcamp', href: '/web-development/front-end-development-bootcamp-training' },
  { name: 'ITIL Foundation Certification', href: '/it-service-management/itil-foundation-certification-training' },
  { name: 'Python Certification Training', href: '/programming/python-programming-certification-training' },
];
