import { gql } from '@apollo/client';

export const GET_CITY_BY_SLUG = gql`
  query getCitiesBySlug($filter: CityFilter, $paging: CursorPaging, $sorting: [CitySort!]) {
    cities(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id: legacy_id
          code
          name
          timezone_abbr
          slug
          status
          timezone {
            id: legacy_id
            name
            offset
            timezone
          }
          country {
            id: legacy_id
            code
            name
            slug
            isd
            region {
              id: legacy_id
              name
              slug
            }
            currency {
              id: legacy_id
              code
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_CITY_BY_PARAMS = gql`
  query getCitiesBySlug($filter: CityFilter, $paging: CursorPaging, $sorting: [CitySort!]) {
    cities(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id: legacy_id
          name
          code
          slug
          timezone_abbr
          timezone {
            id: legacy_id
            name
            offset
            timezone
          }
        }
      }
    }
  }
`;

export const GET_ILB_DATA = gql`
  query getILBDATA($filter: CityFilter, $paging: CursorPaging, $sorting: [CitySort!]) {
    cities(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id: legacy_id
          name
          slug
        }
      }
    }
  }
`;
