import { gql } from '@apollo/client';

export const GET_PAGE_DATA = gql`
  query getPageData($slug: String!, $bot:Int) {
    pageData(slug: $slug,bot:$bot) {
      id
      code
      section
      data
      show_in_header_nav
      is_crawl
    }
  }
`;
