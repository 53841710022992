const numbers = {
  '102': {
    name: 'USA',
    tel: ['+1-469-442-0620'],
    phone: ['+1-832-684-0080'],
    toll: [],
    flag: 'icon-usa',
  },
  '4': {
    name: 'India',
    phone: ['1800-121-9232'],
    toll: ['+91-84484-45027'],
    tel: [],
    flag: 'icon-india',
  },
  '64': {
    name: 'UK',
    phone: ['+44-2036085923'],
    tel: [],
    toll: [],
    flag: 'icon-uk',
  },
  '7': {
    name: 'Singapore',
    phone: ['+65-317-46174'],
    tel: [],
    toll: [],
    flag: 'icon-singapore',
  },
  '9': {
    name: 'Malaysia',
    phone: ['+601548770914'],
    tel: [],
    toll: [],
    flag: 'icon-malaysia',
  },
  '96': {
    name: 'Canada',
    phone: ['+1-613-707-0763'],
    tel: [],
    toll: [],
    flag: 'icon-canada',
  },
  '3': {
    name: 'New Zealand',
    phone: ['+64-36694791'],
    tel: [],
    toll: [],
    flag: 'icon-new-zealand',
  },
  '39': {
    name: 'Ireland',
    phone: ['+353-12708328'],
    tel: [],
    toll: [],
    flag: 'icon-ireland',
  },
  '1': {
    name: 'Australia',
    phone: ['+61-290995641'],
    tel: [],
    toll: [],
    flag: 'icon-australia',
  },
  '105': {
    name: 'UAE',
    phone: ['8000180860'],
    tel: [],
    toll: [],
    flag: 'icon-uae',
  },
  '33': {
    name: 'berlin',
    phone: ['+353-12708328'],
    tel: ['+35312708328'],
  },
  '38': {
    name: 'ireland',
    phone: ['+353-12708328'],
    tel: ['+35312708328'],
  },
  '37': {
    name: 'hungary',
    phone: ['+353-12708328'],
    tel: ['+35312708328'],
  },

  '47': {
    name: 'bulgaria',
    phone: ['+353-12708328'],
    tel: ['+35312708328'],
  },

  '24': {
    name: 'switzerland',
    phone: ['+353-12708328'],
    tel: ['+35312708328'],
  },

  '61': {
    name: 'switzerland',
    phone: ['+353-12708328'],
    tel: ['+35312708328'],
  },

  default: {
    name: 'USA',
    phone: ['+1-469-442-0620'],
    tel: ['+1-832-684-0080'],
    toll: ['+1-832-684-0080'],
    flag: 'icon-usa',
  },
};

export const getPhoneNumberFromCountryId = (countryId: any, type: 'name' | 'phone' | 'tel' | 'all') => {
  //   console.log(countryId);
  switch (type) {
    case 'phone': {
      return numbers[countryId]?.phone[0] || numbers['default'].phone[0];
    }
    case 'tel': {
      return numbers[countryId]?.tel[0] || numbers['default'].tel[0];
    }
    case 'name': {
      return numbers[countryId]?.name || numbers['default'].name;
    }
    case 'all': {
      return numbers[countryId] || numbers['default'];
    }
    default: {
      return numbers['default'].phone[0];
    }
  }
};
