import { ChevronRightIcon } from '@heroicons/react/solid';
import clickEvent from '../../../lib/events/clickEvent';
import Link from 'next/link';
import { useState, useEffect, useContext } from 'react';
import { BlackArrowRight, MenuItemIcon } from '../Icon';
import { MenuItemsProps, MenuItemsData } from './Interface';
import SubMenuItems from './SubMenuitems';
import { GlobalContext } from '../../../pages/_app';
import { trackGa4Events } from '../../../lib/events/ga4/tracker';

const MenuItem: React.FC<MenuItemsProps> = ({ toShow, data, title, isShowNewTag }) => {
  const [isShowMore, SetIsShowMore] = useState(false);

  const { courseName, categoryName } = useContext(GlobalContext);

  useEffect(() => {
    // click event
    isShowMore && clickEvent({ windowObj: window, courseName, categoryName });
  }, [isShowMore]);

  return (
    data?.length && (
      <div className="flex justify-evenly mr-6">
        {data?.slice(0, toShow)?.map(({ ref, title, dataTrackerId, noFollow }: MenuItemsData, index: number) =>
          dataTrackerId?.length ? (
            <a href={ref} key={index}>
              <div
                className="py-5 px-2 mx-2 text-[13px] text-gray-5 font-semibold border-b-2 border-white cursor-pointer hover:border-black leading-6"
                data-tracker-id={dataTrackerId}
              >
                {title}
              </div>
            </a>
          ) : (
            <a href={ref} key={index} rel={noFollow && 'nofollow'}>
              <div className="py-5 px-2 mx-2 text-[13px] text-gray-5 font-semibold border-b-2 border-white cursor-pointer hover:border-black leading-6">
                {title}
              </div>
            </a>
          )
        )}
        {data?.length > toShow && (
          <div className="group">
            <div
              className="inline-block relative text-[#333333] py-5 h-[68px] text-gray-5 text-[13px] font-semibold 
              border-b-2 border-white cursor-pointer group-hover:border-black leading-[26px] duration-[0.3s]"
              onMouseEnter={() => {SetIsShowMore(true); trackGa4Events(`hover-header-menu{${title}}`);}}
              onMouseLeave={() => SetIsShowMore(false)}
            >
              {title || 'More '}

              <span className="pl-[3px]">
                <MenuItemIcon className="relative bottom-[2px]" />
              </span>
              {isShowNewTag && (
                <span className="absolute left-0 top-[11px] h-[15px] py-0.5 px-1.5 text-[8px] leading-[11px] bg-[#544fbc] rounded-[44px] align-middle text-white">
                  New
                </span>
              )}
            </div>
            <div className="relative">
              <div
                className={`${
                  title == 'Help' ? `right-0` : ''
                } absolute  z-50 flex-col hidden bg-white rounded-sm shadow-menu min-w-[202px] group-hover:block duration-[0.3s]`}
                onMouseEnter={() => SetIsShowMore(true)}
              >
                {isShowMore &&
                  data
                    ?.slice(toShow)
                    ?.map(({ ref, title, data, dataTrackerId, noFollow }: MenuItemsData, index: number) =>
                      data ? (
                        // Menu inside menu
                        <SubMenuItems key={index} title={title} data={data} toShow={toShow} />
                      ) : // Single menu
                      dataTrackerId?.length ? (
                        <a
                          href={ref}
                          key={index}
                          rel={noFollow && 'nofollow'}
                          className="px-3 hover:bg-gray-100 duration-[0.3s] block"
                          // data-tracker-id={dataTrackerId}
                        >
                          <div
                            className="py-3 border-b border-gray-100 text-[13px] cursor-pointer whitespace-nowrap"
                            data-tracker-id={dataTrackerId}
                          >
                            {title}
                          </div>
                        </a>
                      ) : (
                        <a
                          href={ref}
                          key={index}
                          className="px-3 hover:bg-gray-100 duration-[0.3s] block"
                          rel={noFollow && 'nofollow'}
                        >
                          <div className="py-3 border-b border-gray-100 text-[13px] cursor-pointer whitespace-nowrap">
                            {title}
                          </div>
                        </a>
                      )
                    )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};
export default MenuItem;
