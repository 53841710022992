import axios from 'axios';
import { getUtmParams, randomCodeGenerator } from '../../../../lib/common/utm';
import { getUserLocationData } from '../../../../lib/common/userLocation';
import { getIP } from '../../../../lib/api/ipApi';
import storeDataToLocal from './StoreDataToLocalStorage';

// form submit handler
export const formSubmitHandler = async (data: any, leadType: string) => {
  // getCurrentLocation
  const location = await getUserLocationData();
  const ipData = await getIP();
  const ipAddress = ipData.ip;
  const utmParams = getUtmParams();
  const randomCode = randomCodeGenerator();
  const defaultData = {
    code: randomCode,
    ip_address: ipAddress,
    utm_parameters: utmParams,
    city_id: location.city.id,
    country_id: location.country.id,
    timezone_name: location?.city?.timezone_name,
  };

  const formData = { ...defaultData, ...data };

  const url = '/v1/leads';

  const headers = {
    'Content-type': 'application/json',
  };

  const response = await axios.post(url, JSON.stringify(formData), {
    headers,
  });

  if (response.data.success == true) {
    //setLocalStorage
    localStorage.setItem('leadLocation', JSON.stringify({ country: formData.country_id }));
    localStorage.setItem('formSubmitedData', JSON.stringify({ ...formData, id: response.data.result.id }));

    let callBackURL = `{"callback":"${process.env.NEXT_PUBLIC_SNS_TRIGGER_DOMAIN}/forms/send_lead_mail/${leadType}/${response.data.result.id}"}`;

    //publish sns message
    if (leadType != 'masterClassBookSeatQuery' && leadType != 'oslFreeTrail') {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BLOG_API}/snsPublish`,
        { url: callBackURL },
        { headers }
      );
    }

    if (leadType == 'masterClassBookSeatQuery') {
      if (formData.isOTPVerified) {
        callBackURL = `{"callback":"${process.env.NEXT_PUBLIC_SNS_TRIGGER_DOMAIN}/forms/send_otp_mail/${response.data.result.id}"}`;
      }
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BLOG_API}/snsPublish`,
        { url: callBackURL },
        { headers }
      );
    }

    // tracking codes
    const obj = document.createElement('iframe');
    obj.style.width = '0px';
    obj.style.height = '0px';
    document.body.appendChild(obj);
    obj.src = '/tracking-codes';

    // for sending otp
    await axios.post(
      process.env.NEXT_PUBLIC_AUTH_LOGIN_API,
      JSON.stringify({
        username: location.country.id == 4 ? formData?.phone : formData?.email,
      }),
      {
        headers,
      }
    );

    return {
      result: {
        id: response.data.result.id,
        message: response.data.result.message,
        status: response.data.success,
      },
    };
  }

  return {
    result: {
      id: '',
      message: 'Lead Not Created',
      status: false,
    },
  };
};

// checkLead
export const checkLead = async (code: string, email: string, blog_id?: number) => {
  const response = await axios.get(`/v1/leads`, {
    params: { code, email, blog_id },
    validateStatus: () => true,
  });

  return response.data.success ? response.data.totalCount : 0;
};

// form blogActiveSubscription
export const blogActiveSubscription = async (data: any, leadType: string) => {
  const formData = { ...data };

  const url = '/v1/activate_subscription';

  const headers = {
    'Content-type': 'application/json',
  };

  const response = await axios.put(url, JSON.stringify(formData), {
    headers,
  });

  if (response.data.success == true) {
    const callBackURL = `{"callback":"${process.env.NEXT_PUBLIC_WEB_DOMAIN}/forms/send_lead_mail/${leadType}/${response.data.result.id}"}`;

    //publish snsmessage
    if (leadType != 'oslFreeTrail') {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_BLOG_API}/snsPublish`,
        { url: callBackURL },
        { headers }
      );
    }

    return {
      result: {
        id: response.data.result.id,
        message: response.data.result.message,
        status: response.data.success,
      },
    };
  }

  return {
    result: {
      id: '',
      message: 'Lead Not Created',
      status: false,
    },
  };
};

export const resendMail = async (leadID: string) => {
  const headers = {
    'Content-type': 'application/json',
  };
  const OTPCallBackURL = `{"callback":"${process.env.NEXT_PUBLIC_SNS_TRIGGER_DOMAIN}/forms/send_otp_mail/${leadID}"}`;
  const result = await axios.post(
    `${process.env.NEXT_PUBLIC_BLOG_API}/snsPublish`,
    { url: OTPCallBackURL },
    { headers }
  );

  return {
    result: {
      id: '',
      message: 'OTP RESEND',
      status: true,
    },
  };
};
