import axios from 'axios';
import { camelCaseConvertor } from './camelCaseConvertor';
import isBotUser from '../common/checkBot';
import gql from '../../lib/graphql/gql';
import {
  GET_COURSE_DATA,
  GET_COURSE_LEARNING_TYPES_DATA,
  GET_COURSE_LIST_DATA,
  GET_COURSE_SEARCH_DATA,
  GET_RELATED_COURSE_DATA,
} from '../../lib/graphql/constants/course.gql';
import { GET_FORM_DATA } from '../../lib/graphql/constants/formData.gql';
import { GET_PAGE_DATA } from '../../lib/graphql/constants/pageData.gql';

// getCourseBySlug
export async function getCourseBySlug(slug: string) {
  // data
  const data = await gql({
    query: GET_COURSE_DATA,
    variables: { filter: { slug: { eq: slug } } },
    queryName: 'courses',
    queryType: 'query',
    context: 'Get courses data',
  });

  return data && data[0];
}

// getCourseContentByCourseID
export async function getCourseContentByCourseID(id: number, isBot: boolean, city_id: number) {
  let courseContentData;

  if (isBot == true) {
    courseContentData = await axios.get(`/kh/courses/${id}/content`, {
      params: { bot_city_id: city_id },
      validateStatus: () => true,
    });
  } else {
    courseContentData = await axios.get(`/kh/courses/${id}/content`, {
      validateStatus: () => true,
    });
  }

  if (courseContentData.data.success == false) return null;

  return camelCaseConvertor(courseContentData.data.result);
}

interface CourseCityParams {
  course_id: number;
  userAgent: string;
  city_id: number;
}

// getCourseCityContentByCourseID
export async function getCourseCityContentByCourseID(params: CourseCityParams) {
  let bot = isBotUser(params.userAgent);

  let courseContentData = await axios.get(`/kh/courses/${params.course_id}/city`, {
    params: { id: params.city_id, bot: bot ? 1 : 0 },
    validateStatus: () => true,
  });

  if (courseContentData.data.success == false) return null;

  return camelCaseConvertor(courseContentData.data.result);
}

// getCoursesLearningType
export async function getCoursesLearningType(courseID: Number, learningType: string) {
  let courseLearningTypeData = await axios.get(`/kh/courses/${courseID}/learning_types`, {
    params: { code: learningType },
    validateStatus: () => true,
  });

  if (courseLearningTypeData.data.success == false) return null;

  return camelCaseConvertor(courseLearningTypeData.data.result);
}

// getCoursesLearningType
export async function addItemold() {
  let cartData = await axios.get(`http://localhost/kh3-website/cart/ajax_add/208`, {
    validateStatus: () => true,
  });

  if (cartData.data.success == false) return null;

  return camelCaseConvertor(cartData.data.result);
}

// getCoursesBySearch
export async function getCoursesBySearch(searchString: string) {
  let variables;

  if (searchString == '') {
    variables = {
      filter: { trending: { eq: 1 }, status: { eq: 1 }, slug:{ neq: 'togaf-prep-course'} },
      paging: { first: 7 },
      sorting: { direction: 'DESC', field: 'trending' },
    };
  } else {
    variables = {
      filter: { name: { iLike: searchString }, status: { eq: 1 }, slug:{ neq: 'togaf-prep-course'} },
      paging: { first: 7 },
      sorting: { direction: 'DESC', field: 'trending' },
    };
  }

  // courseSearchData
  const courseSearchData = await gql({
    query: GET_COURSE_SEARCH_DATA,
    variables,
    queryName: 'courses',
    context: 'getCoursesBySearch',
  });

  if (!courseSearchData) return null;

  const list = arrangeCourseSearchList(courseSearchData, searchString);

  return list;
}

// arrangeCourseSearchList
const arrangeCourseSearchList = (courses, searchString) => {
  try {
    // pattern
    const findPattern = new RegExp(`(${searchString})`, 'gi');

    // list
    const list = courses.map((course) => {
      const courseName = course.name.replace(findPattern, "<span style='font-weight:600;'>$1</span>");
      return {
        title: courseName,
        ref: `/${course?.category?.slug}/${course?.slug}`,
      };
    });

    return list;
  } catch (error) {
    console.error(`arrangeCourseSearchList ${error}`);
  }
};

// getCoursesList
export async function getCoursesList() {
  // coursesList
  const coursesList = await gql({
    query: GET_COURSE_LIST_DATA,
    variables: { filter: { status: { eq: 1 }, slug:{ neq: 'togaf-prep-course'} }, paging: { first: 500 } },
    queryName: 'courses',
    queryType: 'query',
    context: 'Get courses data',
  });
  if (!coursesList?.length) return null;

  return coursesList;
}

// courseListData
export async function courseListData() {
  const unsortedList = await getCoursesList();

  const trending = unsortedList
    .filter((item: any) => item.trending)
    .map((item: any) => ({ id: item.id, name: item.name }));
  const nonTrending = unsortedList
    .filter((item: any) => !item.trending)
    .map((item: any) => ({ id: item.id, name: item.name }));

  return { trending, nonTrending };
}

// learningTypeSessionsCount
export async function learningTypeSessionsCount(courseID: number, cityID: number, learningTypeID: number) {
  const sessionsList = await axios.get(`/kh/courses/${courseID}/total_sessions`, {
    params: { city_id: cityID, learning_type: learningTypeID },
    validateStatus: () => true,
  });

  if (sessionsList.data.success == false) return null;

  return sessionsList.data.result;
}

// getCourseBySlug
export async function getCourseLayoutSections(slug: string, isBot: boolean) {
  // data
  const result = await gql({
    query: GET_PAGE_DATA,
    variables: { slug: slug, bot: isBot == true ? 1 : 0 },
    queryName: 'pageData',
    queryType: 'query-without-edge',
    context: 'getCourseLayoutSections',
  });

  if (result == undefined || !result.length) return { courseLayoutSections: [], suggestedCourseIDs: [] };

  // suggested courseIDs
  let suggestedCourseIDs = [];

  let courseLayoutSections = result.map((obj) => {
    // parseData
    const parseData = JSON.parse(obj.data);

    // check for suggested courses section
    if (obj.code == 'suggested-courses-1') {
      const suggestedCourses = JSON.parse(obj.data);
      suggestedCourseIDs = suggestedCourses?.course_id?.map((course) => Number(course.id)) || []; // convert string numbers values to integer values in array
    }

    // sectionData
    const sectionData = {
      id: obj.id,
      description: parseData,
      show_in_header_nav: parseData.show_in_header_nav,
      is_crawl: parseData.is_crawl,
      order_by: 0,
      layout_section_id: obj.section,
      course_id: 0,
      country_id: 0,
      city_id: 0,
      layout_section: {
        id: obj.section,
        code: obj.code,
        name: obj.code,
        css_file_name: '',
        layout_section_type: {
          id: obj.section,
          code: obj.code,
          name: obj.code,
        },
      },
    };
    return sectionData;
  });

  return { courseLayoutSections, suggestedCourseIDs };
}

// getRelatedCourses
export async function getRelatedCourses(ids: number[]) {
  // relatedCourses
  const relatedCourses = await gql({
    query: GET_RELATED_COURSE_DATA,
    variables: { filter: { legacy_id: { in: ids } } },
    queryName: 'courses',
    queryType: 'query',
    context: 'Get related courses',
  });

  return relatedCourses;
}

// getCourseLearningTypes
export async function getCourseLearningTypes(slug: string) {
  // courseLearningTypes
  const courseLearningTypes = await gql({
    query: GET_COURSE_LEARNING_TYPES_DATA,
    variables: { filter: { slug: { eq: slug } } },
    queryName: 'courses',
    queryType: 'query',
    context: 'Get course learning types',
  });

  return courseLearningTypes[0]?.learning_types;
}
