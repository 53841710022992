import { trackGa4Events } from '../.../../../../../lib/events/ga4/tracker';
import { useContext, useEffect } from 'react';
import { FC, useState } from 'react';
import tw from 'twin.macro';
import { Link, animateScroll as scroll } from 'react-scroll';

const Container = tw.div`
bg-[#111111]
md:px-[65px]
md:py-0
px-0
py-3
w-full
fixed
bottom-0
inline-block
z-[101]
`;

const Content = tw.a`
md:px-[23px] md:text-[13px] md:hover:bg-[#333333] md:leading-[31px] justify-center xs:px-[15px] xs:text-[11px]  xs:leading-[17px] font-regular inline-flex items-center cursor-pointer  text-white
`;
const LargeScreen = tw.div`
md:flex xs:hidden 
`;
const SmallScreen = tw.div`
grid grid-cols-2 md:hidden uppercase font-bold

`;

const FooterSticky: FC<any> = ({
  setFormName,
  setMoengageName,
  layoutCode,
  isWhen1SectionExist,
  ga4EventKey,
  setGa4EventKey,
}) => {
  const [openQuery, setOpenQuery] = useState(false);
  const [openCallBackModal, setOpenCallBackModal] = useState(false);

  const [page, setPageUrl] = useState('');

  useEffect(() => {
    let scheduleURL = window.location.href;

    scheduleURL = scheduleURL.split('?')[0];
    let URLParams = '';
    if (window.location.href.includes('?')) {
      scheduleURL = window.location.href.split('?')[0];
      URLParams = window.location.href.split('?')[1];
    }

    if (scheduleURL.includes('/online-classroom')) {
      scheduleURL = scheduleURL.replace('online-classroom', 'schedule/online-classroom');
    } else if (scheduleURL.includes('/classroom')) {
      scheduleURL = scheduleURL.replace('classroom', 'schedule/classroom');
    } else {
      scheduleURL = `${scheduleURL}/schedule`;
    }

    setPageUrl(scheduleURL);
  });

  const [isShowScrollButton, setIsShowScrollButton] = useState(false);
  const [isShowScheduleEnrolButton, setIsShowScheduleEnrolButton] = useState(false);

  let isShowScrollEnrolButtonInStore = typeof window !== 'undefined' && localStorage.getItem('isShowScrollEnrolButton');

  let isShowScheduleEnrolButtonInStore =
    typeof window !== 'undefined' && localStorage.getItem('isShowScheduleEnrolButton');

  useEffect(() => {
    setIsShowScrollButton((isShowScrollEnrolButtonInStore == 'false' ? false : true) || false);
    setIsShowScheduleEnrolButton((isShowScheduleEnrolButtonInStore == 'false' ? false : true) || false);
  }, [isShowScrollEnrolButtonInStore, isShowScheduleEnrolButtonInStore]);

  return (
    <Container>
      <LargeScreen>
        <Content
          onClick={() => {
            setFormName('DropAQueryForm');
            setMoengageName('Submit_Drop A Query_Footer Sticky');
            trackGa4Events('click-drop-a-query-footer-sticky');
            setGa4EventKey('submit-drop-a-query-footer-sticky');
          }}
        >
          <i className="footer-sticky-bar-icon inline-block footer-query-icon mr-[7px]"></i>
          Drop A Query
        </Content>

        <Content
          onClick={() => {
            setFormName('RequestACallForm');
            setMoengageName('Submit_Request For Call Back_Footer Sticky');
            trackGa4Events('click-request-a-call-footer-sticky');
            setGa4EventKey('submit-request-a-call-footer-sticky');
          }}
        >
          <i className="footer-sticky-bar-icon inline-block footer-call-icon mr-[7px]"></i>
          Request For Call Back
        </Content>

        <Content
          href="mailto:kh.reachus@upgrad.com"
          onClick={() => {
            setOpenCallBackModal(true);
          }}
        >
          <i className="footer-sticky-bar-icon inline-block footer-mail-icon mr-[7px]"></i>
          kh.reachus@upgrad.com
        </Content>
      </LargeScreen>
      <SmallScreen>
        <Content
          className="border-r"
          onClick={() => {
            setFormName('ContactCourseAdvisorForm');
            setMoengageName('Submit_Contact Learning Advisor_Mobile Sticky');
            trackGa4Events('click-course-advisor-footer-sticky');
            setGa4EventKey('submit-course-advisor-footer-sticky');
          }}
        >
          <i className="footer-sticky-bar-icon inline-block  footer-advisor-icon mr-[7px]"></i>
          Course Advisor
        </Content>
        {layoutCode != 'layout-d' && (
          <Content
            href={`${page}`}
            rel="nofollow"
            onClick={() => {
              trackGa4Events('click-view-schedules-sticky-nav');
            }}
          >
            <i className="footer-sticky-bar-icon inline-block footer-schedule-icon mr-[7px]"></i>
            View Schedules
          </Content>
        )}

        {layoutCode == 'layout-d' && isWhen1SectionExist && !isShowScrollButton && isShowScheduleEnrolButton && (
          <Content href={`${page}`} rel="nofollow"  onClick={() => { trackGa4Events('click-view-schedules-sticky-nav'); }}>
            <i className="footer-sticky-bar-icon inline-block footer-schedule-icon mr-[7px]"></i>
            View Schedules
          </Content>
        )}

        {layoutCode == 'layout-d' && isWhen1SectionExist && isShowScrollButton && (
          <Link
            to={'when-1-layout-d'}
            smooth={true}
            duration={500}
            className={`md:px-[23px] md:text-[13px] md:hover:bg-[#333333] md:leading-[31px] justify-center xs:px-[15px] xs:text-[11px]  xs:leading-[17px] font-regular inline-flex items-center cursor-pointer  text-white`}
            onClick={() => {
              trackGa4Events('layout-d-click-enrol-now-footer-sticky-mbl');
            }}
          >
            <i className="footer-sticky-bar-icon inline-block footer-schedule-icon mr-[7px]"></i>
            Enroll Now
          </Link>
        )}

        {layoutCode == 'layout-d' && isWhen1SectionExist && !isShowScrollButton && !isShowScheduleEnrolButton && (
          <Content
            className="border-r"
            onClick={() => {
              setFormName('RequestABatch');
              setMoengageName('Submit_Request A Batch_When1');
              trackGa4Events('layout-d-click-request-a-batch-footer-sticky-mbl');
              setGa4EventKey('layout-d-submit-request-a-batch-footer-sticky-mbl');
            }}
          >
            <i className="footer-sticky-bar-icon  inline-block  footer-schedule-icon mr-[7px]"></i>
            Request A Batch
          </Content>
        )}

        {layoutCode == 'layout-d' && !isWhen1SectionExist && (
          <Content
            className="border-r"
            onClick={() => {
              setFormName('RequestABatch');
              setMoengageName('Submit_Request A Batch_When1');
              trackGa4Events('layout-d-click-request-a-batch-footer-sticky-mbl');
              setGa4EventKey('layout-d-submit-request-a-batch-footer-sticky-mbl');
            }}
          >
            <i className="footer-sticky-bar-icon  inline-block  footer-schedule-icon mr-[7px]"></i>
            Request A Batch
          </Content>
        )}
      </SmallScreen>
    </Container>
  );
};

export default FooterSticky;
