import React, { useContext, useEffect, useState } from 'react';
import tw, { styled, css } from 'twin.macro';
import SectionCountry from './SectionCountry';
import SectionDisclaimer from './SectionDisclaimer';
import SectionContactUs from './SectionContactUs';
import SectionServices from './SectionServices';
import SectionServices2 from './SectionServices2';
import Link from 'next/link';
import FooterSticky from 'components/layout-a/sections/FooterSticky';
import { FormsContext } from '../../../pages/_app';
import viewPortCheck from '../../../lib/common/observer';

const FooterWrapper = tw.footer`bg-black relative block z-[21] px-4 xl:px-[50px] text-white`;

const FooterContainer = styled.div(() => [
  tw`pt-6`,
  tw`md:pt-[27px]`,
  // css`
  //   @media screen and (min-width: 1366px) {
  //     padding-left: 100px;
  //     padding-right: 100px;
  //   }
  // `,
]);

const date = new Date();
let year = date.getFullYear();

const SectionCopyright: React.FC = () => (
  <section tw="pt-6 pb-16 text-xs md:text-sm md:flex md:justify-between md:items-start border-t border-[#222222]">
    <p tw="leading-5">© 2011-{year}, KnowledgeHut Solutions Private Limited. All Rights Reserved</p>
    <div>
      <ul tw="md:flex">
        <li tw="pt-4 md:pr-10 md:pt-0">
          <a href="/privacy-policy">Privacy policy</a>
        </li>
        <li tw="pt-4 md:pt-0">
          <a href="/terms-conditions">Terms of service</a>
        </li>
      </ul>
    </div>
  </section>
);

const Footer: React.FC<{ isDisplayFooterStick: boolean; layoutCode: string, isWhen1SectionExist:boolean}> = ({
  isDisplayFooterStick,
  layoutCode,
  isWhen1SectionExist
}) => {
  const [isFooterSticky, setFooterSticky] = useState(false);
  let [viewRef, isView] = viewPortCheck();
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 500) {
      setFooterSticky(true);
      window.removeEventListener('scroll', handleScroll);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [formName, setFormName, moengageName, setMoengageName, ga4EventKey, setGa4EventKey] = useContext(FormsContext);

  return (
    <>
      <FooterWrapper ref={viewRef as any} className={`${!isDisplayFooterStick && 'pb-7'}`}>
        <FooterContainer>
          <SectionContactUs />
          <SectionCountry />
          <SectionServices />
          <SectionServices2 />
          <SectionDisclaimer />
          <SectionCopyright />
        </FooterContainer>
      </FooterWrapper>

      {isDisplayFooterStick && isFooterSticky && (
        <FooterSticky setFormName={setFormName} setMoengageName={setMoengageName} layoutCode={layoutCode} isWhen1SectionExist ={isWhen1SectionExist} ga4EventKey={ga4EventKey} setGa4EventKey={setGa4EventKey}/>
      )}
    </>
  );
};

export default Footer;
