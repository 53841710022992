import { FC, useState } from 'react';
import { MenuItemsData, MenuItemsProps } from './Interface';
import { ChevronRightIcon } from '@heroicons/react/solid';

const SubMenuItems: FC<MenuItemsProps> = ({ title, toShow, data }) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <div>
      <div className="group relative hover:bg-gray-100 duration-[0.3s]">
        <div
          className={`flex justify-between px-3 items-center text-[13px] cursor-pointer ${showMore && 'bg-gray-100'}`}
          onMouseEnter={() => setShowMore(true)}
          onMouseLeave={() => setShowMore(false)}
        >
          <div className="flex w-full border-b border-gray-100 py-3 justify-between whitespace-nowrap">
            {title || 'More '}
            <span className={`transition-all delay-75 ${showMore && 'rotate-180'}`}>
              {/* <BlackArrowRight /> */}
              <ChevronRightIcon className="w-5 text-gray-600" />
            </span>
          </div>
        </div>
        {/* <div
          className="absolute top-0 right-full z-50 flex-col hidden bg-white rounded-sm shadow-md min-w-[202px] group-hover:block duration-[0.3s]"
          onMouseEnter={() => setShowMore(true)}
          onMouseLeave={() => setShowMore(false)}
        >
          {showMore &&
            data?.slice(toShow).map(({ ref, title, data }: MenuItemsData, index: number) => (
              <a href={ref} key={index} className="px-3 hover:bg-gray-100 duration-[0.3s] block">
                <div
                  className="py-3 border-b border-gray-100 text-[13px] cursor-pointer"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></div>
              </a>
            ))}
        </div> */}
        <div
          className="absolute top-0 right-full z-50 flex-col hidden bg-white rounded-sm shadow-md min-w-[202px] group-hover:block duration-[0.3s]"
          onMouseEnter={() => setShowMore(true)}
          onMouseLeave={() => setShowMore(false)}
        >
          {showMore &&
            data?.slice(toShow).map(({ ref, title, data, dataTrackerId }: MenuItemsData, index: number) =>
              dataTrackerId?.length ? (
                <a
                  href={ref}
                  key={index}
                  className="px-3 hover:bg-gray-100 duration-[0.3s] block"
                  data-tracker-id={dataTrackerId}
                >
                  <div
                    className="py-3 border-b border-gray-100 text-[13px] cursor-pointer"
                    data-tracker-id={dataTrackerId}
                  >
                    {title}
                  </div>
                </a>
              ) : (
                <a href={ref} key={index} className="px-3 hover:bg-gray-100 duration-[0.3s] block">
                  <div className="py-3 border-b border-gray-100 text-[13px] cursor-pointer">{title}</div>
                </a>
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default SubMenuItems;
