import { FC, useState } from 'react';
import { trendingData } from './TrendingCourses';
import Link from 'next/link';
import TrendingProps, { TrendingItem } from './Interface';
import tw from 'twin.macro';

const Container = tw.div`
	rounded w-full bg-white shadow-[0 0 8px 0 rgb(0 0 0 / 20%)]
`;

const TrendingDropdown: FC<TrendingProps> = ({ data }) => {
  return (
    <Container>
      {data?.map(({ title, ref }: TrendingItem, index: number) => (
        <a key={index} href={ref} >
          <div className="bg-white hover:bg-gray-100">
            <span  id="searchList"
              className="text-sm leading-[19px] text-[#231f20] font-regular p-3 border-b  border-[#f3f3f3] text-ellipsis block w-full"
              dangerouslySetInnerHTML={{ __html: title }}
            ></span>
          </div>
        </a>
      ))}
    </Container>
  );
};

export default TrendingDropdown;
