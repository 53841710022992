import { FC } from 'react';
import Image from 'next/image';
import strMobileImage from '../../../public/website_next_images/Mobile-banner-new.svg';
import strDesktopImage from '../../../public/website_next_images/PAY-DAY-OCT-NOV-WEB-new.svg';

export default function TopBanner({ topBannerData }: any) {
  if (!topBannerData) {
    return <></>;
  }

  let isShowTopBanner = true;
  let redirectingUrl = topBannerData?.data?.link;
  let altName = topBannerData?.data?.altname;

  let backgroundColor = topBannerData?.data?.backgroundcolor;
  let mobileImage =
    typeof topBannerData?.data?.mobileimage === 'string'
      ? JSON.parse(topBannerData?.data?.mobileimage)
      : topBannerData?.data?.mobileimage;
  let desktopImage =
    typeof topBannerData?.data?.image === 'string'
      ? JSON.parse(topBannerData?.data?.image)
      : topBannerData?.data?.image;

  const CommonComponent = () => (
    <>
      <div
        className={`hidden md:block relative  h-[56px]`}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <Image
          src={`${process.env.NEXT_PUBLIC_AWS_URI}/${desktopImage[0].key}`}
          alt={altName}
          priority
          layout="fill"
          className="object-cover"
        />
      </div>

      <div
        className={`block relative md:hidden h-[56px]`}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <Image
          src={`${process.env.NEXT_PUBLIC_AWS_URI}/${mobileImage[0].key}`}
          alt={`Flash Sale Banner mobile`}
          priority
          layout="fill"
        />
      </div>
    </>
  );

  return (
    <>
      {isShowTopBanner && (
        <>
          {redirectingUrl ? (
            <a href={redirectingUrl} target="_blank" rel="noopener noreferrer">
              <CommonComponent />
            </a>
          ) : (
            <CommonComponent />
          )}
        </>
      )}
    </>
  );
}
