import axios from 'axios';
import {
  GET_COUNTRIES_ISD_LIST,
  GET_COUNTRIES_LIST,
  GET_COUNTRY_BY_NAME,
  GET_COUNTRY_CITIES_LIST,
  GET_DEFAULT_COUNTRY_CITY,
} from '../../lib/graphql/constants/country.gql';
import gql from '../../lib/graphql/gql';
import { camelCaseConvertor } from './camelCaseConvertor';

// getCountryByBName
export async function getCountryByBName(name: string) {
  const countryData = await gql({
    query: GET_COUNTRY_BY_NAME,
    variables: { filter: { name: { iLike: name } } },
    queryName: 'countries',
    queryType: 'query',
    context: 'getCountryByName',
  });

  if (!countryData?.length) return null;

  return camelCaseConvertor(countryData && countryData[0]);
}

// getDefaultCityByCountryID
export async function getDefaultCityByCountryID(id: number) {
  const countryData = await gql({
    query: GET_DEFAULT_COUNTRY_CITY,
    variables: { filter: { legacy_id: { eq: id } } },
    queryName: 'countries',
    queryType: 'query',
    context: 'getDefaultCityByCountryID',
  });

  if (!countryData.length) return null;

  return countryData[0].cities;
}

// getCountriesISDList
export async function getCountriesISDList() {
  const countriesList = await gql({
    query: GET_COUNTRIES_ISD_LIST,
    variables: {
      sorting: [
        { direction: 'DESC', field: 'trending' },
        { direction: 'ASC', field: 'order_by' },
      ],
      filter: { status: { is: true } },
      paging: { first: 500 },
    },
    queryName: 'countries',
    queryType: 'query',
    context: 'getCountriesISDList',
  });

  if (!countriesList.length) return null;

  return countriesList;
}

// getCountriesList
export async function getCountriesList() {
  const countriesList = await gql({
    query: GET_COUNTRIES_LIST,
    variables: {
      sorting: [
        { direction: 'DESC', field: 'trending' },
        { direction: 'ASC', field: 'order_by' },
      ],
      filter: { status: { is: true } },
      paging: { first: 500 },
    },
    queryName: 'countries',
    queryType: 'query',
    context: 'getCountriesList',
  });

  if (!countriesList?.length) return null;

  const trendingCountryCities = [];

  const nonTrendingCountryCities = [];

  const countriesISD = [];

  countriesList.map((data) => {
    const list = { id: data.id, name: data.name };

    // countriesISDs
    countriesISD.push({
      code: data.isd,
      country: data.name,
    });

    if (data.trending == 1) {
      trendingCountryCities.push(list);
    } else {
      nonTrendingCountryCities.push(list);
    }
  });

  return { trendingCountryCities, nonTrendingCountryCities, countriesISD };
}

// getCitiesByCountryID
export async function getCitiesByCountryID(id: number) {
  const countryCitiesList = await gql({
    query: GET_COUNTRY_CITIES_LIST,
    variables: { filter: { legacy_id: { eq: id }, status: {eq: 1} } },
    queryName: 'countries',
    queryType: 'query',
    context: 'getCitiesByCountryID',
  });

  if (!countryCitiesList?.length) return [];

  let cities = [];

  countryCitiesList[0].cities.map((data) => {
    cities.push({ id: data.id, name: data.name });
  });

  return cities;
}
