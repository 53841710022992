// getCookieByName
export const getCookieByParams = (params: any) => {
  const result: any = {};
  const value: string = `; ${document.cookie}`;
  Object.keys(params).forEach((key) => {
    const parts: any = value.split(`; ${params[key]}=`);
    if (parts.length === 2) {
      result[key] = parts.pop().split(";").shift();
    } else {
      result[key] = "";
    }
  });
  return result;
};

// getUtmParams
export const getUtmParams = () => {
  const utmParameters = getCookieByParams({
    VISITS: "_uc_visits",
    ILANDPAGE: "_uc_initial_landing_page",
    LREFERRER: "_uc_last_referrer",
    IREFERRER: "_uc_referrer",
    UCONTENT: "_uc_utm_content",
    UCAMPAIGN: "_uc_utm_campaign",
    UMEDIUM: "_uc_utm_medium",
    USOURCE: "_uc_utm_source",
    UTERM: "_uc_utm_term",
  });

  return utmParameters;
};

// randomCodeGenerator
export const randomCodeGenerator = () => {
  const string: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const stringArr: string[] = string.split("");
  const n = stringArr.length;

  for (let i = 0; i < n - 1; ++i) {
    var j = Math.floor(Math.random() * n);

    var temp = stringArr[i];
    stringArr[i] = stringArr[j];
    stringArr[j] = temp;
  }
  const codeNumber = Math.floor(1000000 + Math.random() * 9000000);
  const codeString = stringArr.join("").substring(0, 3);

  return codeNumber + codeString;
};
