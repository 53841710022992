import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import tw from 'twin.macro';

// dynamic imports
const DynamicNewsLetterSubscription = dynamic(() =>
  import('../../layout-a/forms/NewsLetterSubscription').then((mod: any) => mod.NewsLetterSubscription)
);

const Heading = tw.span`text-sm md:text-base font-bold pb-4 md:pb-5 md:leading-[16px] block`;
const ContactWrapper = tw.section`flex flex-col md:pb-7 md:grid md:grid-cols-2 md:gap-[30px] xl:grid-cols-12`;
const SubscriptionInput = tw.input`flex-1 p-[10px] text-white placeholder:text-white text-base outline-none bg-[#191919] border border-[#303030]  min-w-0 h-[46px]`;
const SubscriptionButton = tw.button`bg-[#26d400] px-3 py-4 uppercase font-semibold text-sm rounded-r-md leading-4 h-[46px]`;

const SectionContactUs: React.FC = () => {
  // const { observe, inView } = useInView();
  const socialIcons = [
    {
      icon: "icon-linkedin-large",
      link: "https://www.linkedin.com/company/upgradknowledgehut/",
      follow: false,
    },
    {
      icon: "icon-facebook-large",
      link: "https://www.facebook.com/KnowledgeHut.Global",
      follow: false,
    },
    {
      icon: "icon-instagram",
      link: "https://www.instagram.com/knowledgehut.global/",
      follow: false,
    },
    {
      icon: "icon-youtube",
      link: "https://www.youtube.com/user/TheKnowledgehut",
      follow: false,
    },
    {
      icon: "icon-twitter-large",
      link: "https://twitter.com/KnowledgeHut",
      follow: false,
    },
    {
      icon: "icon-rss-large",
      link: "https://www.knowledgehut.com/blog/feed",
      follow: true,
    },
  ];
  // Payment icons
  const paymentIcons = [
    {
      icon: 'icon-paypal',
    },
    {
      icon: 'icon-american-express',
    },
    {
      icon: 'icon-maestro',
    },
    {
      icon: 'icon-citrus',
    },
    {
      icon: 'icon-master-card',
    },
    {
      icon: 'icon-visa',
    },
    {
      icon: 'icon-after-pay',
    },
  ];
  return (
    <ContactWrapper>
      {/* <ContactWrapper ref={observe}> */}
      <div tw="xl:col-span-3">
        <Heading>Connect with us</Heading>
        <ul tw="flex justify-start">
          {socialIcons.map((element, idx) => (
            <li key={idx} tw="pr-[25px]">
              <Link href={element.link} passHref>
                {element.follow ? (
                  <a target="_blank">
                    <i
                      className={`inline-block ${element.icon} blog-icons`}
                    ></i>
                  </a>
                ) : (
                  <a target="_blank" rel="noopener noreferrer nofollow">
                    <i
                      className={`inline-block ${element.icon} blog-icons`}
                    ></i>
                  </a>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div tw="pt-7 md:pt-0 xl:col-span-5">
        <Heading>Get Our Weekly Newsletter</Heading>
        <DynamicNewsLetterSubscription />
      </div>
      <div tw="pt-7 pb-6 md:pt-0 xl:col-span-4">
        <Heading>We Accept</Heading>
        {/* payment methods goes here! */}
        <ul tw="flex justify-start flex-wrap">
          {paymentIcons.map((element, idx) => (
            <li key={idx} tw="pr-2">
              <i className={`inline-block ${element.icon} blog-icons`}></i>
            </li>
          ))}
        </ul>
      </div>
    </ContactWrapper>
  );
};

export default SectionContactUs;
