import { useContext } from 'react';
import axios from 'axios';
import { type } from 'os';
import { ga4Events } from './constant';
declare const window: any;


export const trackGa4Events: Function = async (eventKey: string, formData: any) => {

  if (typeof eventKey == 'undefined') return;

  let dynamicEventName = '';
  if (eventKey.includes('{') && eventKey.includes('}')) {
    dynamicEventName = eventKey.substring(eventKey.indexOf("{") + 1, eventKey.lastIndexOf("}"));
    eventKey = eventKey.substring(0, eventKey.indexOf("{"));
    dynamicEventName = dynamicEventName.replace(/[/]/g, '');
  }

  if (typeof ga4Events[eventKey] == 'undefined') return;

  let ga4EventData = JSON.parse(JSON.stringify(ga4Events[eventKey]));

  // Dynamic Name
  if (dynamicEventName)
    Object.keys(ga4EventData).forEach((key, index) => {
      if (key == 'short_name') {
        let dynamicEventArr = dynamicEventName.split(" ");

        if (dynamicEventArr.length > 1) {
          let eventShortName = '';
          dynamicEventArr.map(function (value, index, array) {
            eventShortName += value.substring(0, 3);
          });

          ga4EventData[key] = ga4EventData[key]?.replace(/{value}/g, eventShortName.toLowerCase());
        }
        else
          ga4EventData[key] = ga4EventData[key]?.replace(/{value}/g, dynamicEventName.substring(0, 3).toLowerCase());
      }
      else if (key == 'extras' && typeof ga4EventData.extras != "undefined") {
        Object.keys(ga4EventData.extras).forEach((ekey, eindex) => {
          ga4EventData.extras[ekey] = ga4EventData.extras[ekey].replace(/{value}/g, dynamicEventName.substring(0, 40));
        });
      }
      else if (key == 'parameters' && typeof ga4EventData.parameters != "undefined") {
        Object.keys(ga4EventData.parameters).forEach((ekey, eindex) => {
          ga4EventData.parameters[ekey] = ga4EventData.parameters[ekey].replace(/{value}/g, dynamicEventName.substring(0, 40));
        });
      }
      else
        ga4EventData[key] = ga4EventData[key].replace(/{value}/g, dynamicEventName);
    });

  const courseData: any = localStorage.getItem('courseData');

  const categoryName = JSON.parse(courseData)?.categoryName;
  const courseName = JSON.parse(courseData)?.courseName;

  // Common fields
  let commonDataLayer: any = {
    category_id: categoryName,
    product_id: formData?.course_name || courseName,
    page_location: formData?.page || window.location.href,
    whatsapp_consent: formData?.whatsapp_subscription_status || '',
    form_id: formData?.static_lead_type?.toUpperCase() || '',
    properties: {
      first_name: formData?.first_name || '',
      last_name: formData?.last_name || '',
      email: formData?.email || '',
      page_url: formData?.page || '',
    }
  };

  const courseRemoveSup = commonDataLayer?.product_id?.replace(/<sup>/g, '');

  commonDataLayer['product_id'] = courseRemoveSup?.replace(/<\/sup>/g, '');


  // Remove all empty values from object
  commonDataLayer = Object.entries(commonDataLayer).reduce((acc, [k, v]) => (v ? { ...acc, [k]: v } : acc), {});

  try {
    if (ga4EventData.action == 'Submit') {
      if (formData?.course_id) {
        const response = await axios.get(`${process.env.NEXT_PUBLIC_API}/kh/courses/${formData.course_id}`);

        if (response?.data?.result[0]?.category.name)
          commonDataLayer['category_id'] = response?.data?.result[0]?.category.name;
      }
    }

    let arrGa4key = ga4EventData.name.split('_');

    // Get page section value from constant
    commonDataLayer = {
      ...commonDataLayer,
      page_section: ga4EventData?.page_section ? ga4EventData.page_section : arrGa4key[arrGa4key?.length - 1],
    };

    let allParameters = { ...formData?.parameters, ...ga4EventData?.parameters };

    allParameters = Object.keys(allParameters).length === 0 ? '' : allParameters;

    let eventObj = {
      event: ga4EventData.action,
      event_category: ga4EventData.short_name,
      event_label: ga4EventData.label,
      parameters: allParameters,
      ...ga4EventData?.extras,
      ...commonDataLayer,
    };

    // Remove all empty values from object
    eventObj = Object.entries(eventObj).reduce((acc, [k, v]) => (v ? { ...acc, [k]: v } : acc), {});

    if (process.env.NEXT_PUBLIC_NODE_ENV != "production")
      console.log(eventObj);
    else
      window.dataLayer.push(eventObj);

  } catch (e) {
    console.log({ gtagError: e });
  }
};