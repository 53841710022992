import { PAGE } from '../../interfaces/page';
import { getPageData } from '../../lib/api/page';
import publicIp from 'public-ip';
import { setLocation } from './location';

// getUserLocationData
export async function getUserLocationData() {
  let getUserLocationCookieResponse = getUserLocationCookieData('user-location');

  if (getUserLocationCookieResponse) {
    return JSON.parse(getUserLocationCookieResponse);
  } else {
    let responseData;

    responseData = await setLocation('ip', '', '');

    document.cookie = `${'user-location'}=${encodeURIComponent(JSON.stringify(responseData))}; max-age=31556952;path=/; domain=.knowledgehut.com`;

    return responseData;
  }
}

// getUserLocationCookieData
const getUserLocationCookieData = (name: string) => {
  const cookies = document.cookie.split(';');
  let value: string = '';
  cookies.forEach((cookie) => {
    if (cookie.trim().startsWith(name)) {
      value = cookie.replace(`${name}=`, '');
      value = decodeURIComponent(value);
    }
  });
  return value;
};
