import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { trackGa4Events } from '../../../lib/events/ga4/tracker';

const HeaderIcon: React.FC = () => {
  const router = useRouter();

  const handleClick = (e: any) => {
    e.preventDefault();
    trackGa4Events('homepage_click_Khut_logo_header');
    window.location.href = '/';
  };

  return (
    // Header Image
    <div className="w-auto cursor-pointer md:w-[122px]" onClick={handleClick}>
      <img
        src={'https://d2o2utebsixu4k.cloudfront.net/KH-New-logo/kh-desktop-new-logo.svg'}
        alt="Knowledgehut Logo"
        width="100%"
        height="44"
      />
    </div>
  );
};

export default HeaderIcon;
