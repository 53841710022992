import React, { useCallback } from 'react';
import { IButtonProps } from './Button.types';

const Button = ({
  type,
  children,
  propStyle,
  onHandleClick,
  isDisabled,
  className,
  startIconClassName,
  endIconClassName,
  dataTracker,
}: IButtonProps) => {
  const _renderTypeOfButton = useCallback((type: string) => {
    let styles = '';
    switch (type) {
      case 'primary': {
        styles = 'border-transparent py-2 px-3 bg-gradient-to-r from-orange to-pink text-white';
        break;
      }
      case 'outline': {
        styles = 'py-2 px-3 bg-white text-black border-black';
        break;
      }
      case 'secondary': {
        styles = 'border-transparent py-2 px-3 bg-white text-white bg-custom-blue-50';
        break;
      }
      case 'tertiary': {
        styles =
          'border-transparent hover:bg-gradient-to-r from-violet to-custom-blue-100 hover:text-white py-2 px-3 text-gray-200 bg-gray-100';
        break;
      }
      case 'hoverOutlined': {
        styles = 'p-[6px] bg-transparent text-black border-transparent rounded-full hover:border-black';
        break;
      }
      case 'outline-white': {
        styles = 'py-2 px-3 bg-transparent text-white border-white';
        break;
      }
    }

    return styles;
  }, []);

  const typeOfButton = _renderTypeOfButton(type);
  const buttonClass = [
    'border border-solid rounded w-full',
    `${isDisabled ? 'cursor-not-allowed pointer-events-none opacity-50' : ''}`,
    typeOfButton,
  ].join(' ');

  return dataTracker?.length ? (
    <button style={propStyle} onClick={onHandleClick} type="button" className={`${buttonClass} ${className}`} data-tracker-id={dataTracker}>
      {/* {startIconClassName && <i className={`${startIconClassName} `}></i>} */}
      {startIconClassName && startIconClassName}
      {children}
      {/* {endIconClassName && <i className={`${endIconClassName} `}></i>} */}
      {endIconClassName && endIconClassName}
    </button>
  ) : (
    <button style={propStyle} onClick={onHandleClick} type="button" className={`${buttonClass} ${className}`}>
      {/* {startIconClassName && <i className={`${startIconClassName} `}></i>} */}
      {startIconClassName && startIconClassName}
      {children}
      {/* {endIconClassName && <i className={`${endIconClassName} `}></i>} */}
      {endIconClassName && endIconClassName}
    </button>
  );
};

export default Button;
