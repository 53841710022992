import axios from 'axios';

// getLocationByIPAPI
export async function getLocationByIPAPIOLD(ip: string) {
  const key = '14ND9zd0kewPGap';
  const api = `https://pro.ip-api.com/json/${ip}?key=${key}`;

  const response = await axios.get(api, {
    validateStatus: () => true,
  });

  if (!response.data.status) {
    console.error('IP-API-ERROR : Location Not Found');
    return { status: false };
  }

  return response.data;
}

// getLocationByIPAPI
export async function getLocationByIPAPI(ip: string) {
  const api = `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/api/ipAPI?ip=${ip}`;

  const response = await axios.get(api, {
    validateStatus: () => true,
  });
  
  if (!response.data.status) {
    console.error('IP-API-ERROR : Location Not Found');
    return { status: false };
  }

  return response.data;
}

// getIP
export async function getIP() {
  const api = `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/api/ip`;

  const response = await axios.get(api, {
    validateStatus: () => true,
  });

  return response.data;
}
