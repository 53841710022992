import { gql } from '@apollo/client';

export const GET_COUNTRY_BY_NAME = gql`
  query getCountryByName($filter: CountryFilter, $paging: CursorPaging, $sorting: [CountrySort!]) {
    countries(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id: legacy_id
          code
          name
          isd
          slug
          status
          currency {
            id: legacy_id
            name
          }
          region {
            id:legacy_id
            name
            slug
          }
        }
      }
    }
  }
`;

export const GET_COUNTRIES_LIST = gql`
  query getCountriesList($filter: CountryFilter, $paging: CursorPaging, $sorting: [CountrySort!]) {
    countries(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id: legacy_id
          code
          name
          isd
          slug
          status
          trending
        }
      }
    }
  }
`;

export const GET_COUNTRY_CITIES_LIST = gql`
  query getCountriesList($filter: CountryFilter, $paging: CursorPaging, $sorting: [CountrySort!]) {
    countries(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          cities {
            id: legacy_id
            order_by
            code
            name
            slug
            status
          }
        }
      }
    }
  }
`;

export const GET_DEFAULT_COUNTRY_CITY = gql`
  query getDefaultCountryCity($filter: CountryFilter, $paging: CursorPaging, $sorting: [CountrySort!]) {
    countries(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id: legacy_id
          cities: default_city {
            id: legacy_id
            name
            slug
            code
            timezone_abbr
            country {
              id: legacy_id
              name
              code
              slug
              isd
              region {
                id: legacy_id
                name
                slug
              }
              currency {
                id: legacy_id
                name
                code
              }
            }
            timezone {
              id: legacy_id
              name
              offset
              timezone
            }
          }
        }
      }
    }
  }
`;

export const GET_COUNTRIES_ISD_LIST = gql`
  query getDefaultCountryCity($filter: CountryFilter, $paging: CursorPaging, $sorting: [CountrySort!]) {
    countries(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          code: isd
          country: name
        }
      }
    }
  }
`;
