import axios from 'axios';
import { GET_CITY_BY_PARAMS } from '../../lib/graphql/constants/city.gql';
import gql from '../../lib/graphql/gql';
import { camelCaseConvertor } from './camelCaseConvertor';

// getCityBySlug
export async function getStateByName(name: string, city_name: string, timezone_name: string) {
  const data = await gql({
    query: GET_CITY_BY_PARAMS,
    variables: { filter: { or: [{ name: { iLike: city_name } }, { name: { iLike: name } }] }, paging: { first: 1 } },
    queryName: 'cities',
    queryType: 'query',
    context: 'getStateByName',
  });

  if (!data?.length) return null;

  const result = [];

  result['cities'] = data[0];

  return result;
}
