import Script from 'next/script';

const LazyLoadScripts = ({ pageName }: { pageName: string }) => {
  return (
    <>
      {/* {Mo engage} */}
      {/* <Script
        id="Moengage"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `(function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],h={onsite:["getData","registerCallback"]};for(k
                in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return
                t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage') 
                
                Moengage = moe({
                  app_id:"RWU0KE0IEX7099NTQT7FBR93",
                  debug_logs: ${process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 0 : 1},
                  cluster: "DC_3"
                }); 
                `,
        }}
      ></Script> */}
      {/* {Instana} */}
      <Script
        strategy="lazyOnload"
        id="instana"
        dangerouslySetInnerHTML={{
          __html: ` (function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},
            n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");
            ineum('reportingUrl', 'https://eum-green-saas.instana.io');
            ineum('key', 'vJHAOKCMSgKcGUend9vgaQ');
            ineum('trackSessions');`,
        }}
      />

      <Script
        strategy="lazyOnload"
        id="inst"
        crossOrigin="anonymous"
        src="https://eum.instana.io/1.6.6/eum.min.js"
        integrity="sha384-NeTJf4ldSF1mEi0eQixH+QRzFw1tfvsgXvdEW68lI2fB249vBPpxV1uwLB6xs0DU"
      />
      {/* {Instana} */}

      {/* {UTM} */}
      <Script
        src="https://d12ue6f2329cfl.cloudfront.net/resources/utm_form-1.2.0.min.js"
        id="utmSource"
        strategy="lazyOnload"
      ></Script>

      {/* {zopim} */}
      {pageName != 'MasterClass' && (
        <Script
          id="zopim"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
            d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
            _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");
            $.src="https://v2.zopim.com/?1nYTxEgVmvd5mYJ4Oo8tLPC5gZzJ3OsZ";z.t=+new Date;$.
            type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");`,
          }}
        ></Script>
      )}

      {/* start Optimise Pixel Code  */}
      <Script
        id="Optimise"
        strategy="lazyOnload"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `OMID=2325002;OPID=53171;ORef=escape(window.parent.location.href);!function(){var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="//track.omguk.com/e/qs/?action=Content&MID=" + OMID + "&PID=" + OPID + "&ref="+ ORef;var b=document.getElementsByTagName("body")[0];if(b)b.appendChild(a,b);else{var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b)}}();`,
        }}
      ></Script>
      {/* end Optimise Pixel Code  */}

      {/*  start Admitad Pixel Code  */}
      <Script
        id="Admitad"
        strategy="lazyOnload"
        src="https://www.artfut.com/static/tagtag.min.js?campaign_code=6a0bc7b319"
        onError={
          'var self = this;window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers=ADMITAD.Helpers||{},ADMITAD.Helpers.generateDomains=function(){for(var e=new Date,n=Math.floor(new Date(2020,e.getMonth(),e.getDate()).setUTCHours(0,0,0,0)/1e3),t=parseInt(1e12*(Math.sin(n)+1)).toString(30),i=["de"],o=[],a=0;a<i.length;++a)o.push({domain:t+"."+i[a],name:t});return o},ADMITAD.Helpers.findTodaysDomain=function(e){function n(){var o=new XMLHttpRequest,a=i[t].domain,D="https://"+a+"/";o.open("HEAD",D,!0),o.onload=function(){setTimeout(e,0,i[t])},o.onerror=function(){++t<i.length?setTimeout(n,0):setTimeout(e,0,void 0)},o.send()}var t=0,i=ADMITAD.Helpers.generateDomains();n()},window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers.findTodaysDomain(function(e){if(window.ADMITAD.dynamic=e,window.ADMITAD.dynamic){var n=function(){return function(){return self.src?self:""}}(),t=n(),i=(/campaign_code=([^&]+)/.exec(t.src)||[])[1]||"";t.parentNode.removeChild(t);var o=document.getElementsByTagName("head")[0],a=document.createElement("script");a.src="https://www."+window.ADMITAD.dynamic.domain+"/static/"+window.ADMITAD.dynamic.name.slice(1)+window.ADMITAD.dynamic.name.slice(0,1)+".min.js?campaign_code="+i,o.appendChild(a)}});' as any
        }
      ></Script>

      <Script
        id="Admitad2"
        strategy="lazyOnload"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
              var cookie_name = 'deduplication_cookie';
              var days_to_store = 90;
              var deduplication_cookie_value = 'AdmitAd';
              var channel_name = 'utm_source';
              getSourceParamFromUri = function () {
                var pattern = channel_name + '=([^&]+)';
                var re = new RegExp(pattern);
                return (re.exec(document.location.search) || [])[1] || '';
              };
              getSourceCookie = function () {
                var matches = document.cookie.match(new RegExp(
                  '(?:^|; )' + cookie_name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
                ));
                return matches ? decodeURIComponent(matches[1]) : undefined;
              };
              setSourceCookie = function () {
                var param = getSourceParamFromUri();
                if (!param) { return; }
                var period = days_to_store * 60 * 60 * 24 * 1000;	// in seconds
                var expiresDate = new Date((period) + +new Date);
                var cookieString = cookie_name + '=' + param + '; path=/; expires=' + expiresDate.toGMTString();
                document.cookie = cookieString;
                document.cookie = cookieString + '; domain=.' + location.host;
              };
              setSourceCookie();`,
        }}
      ></Script>
      {/* End Admitad Pixel Code  */}

      {/* Facebook Pixel Code */}
      <Script
        id="facebook"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${process.env.NEXT_PUBLIC_FB_PIXEL_ID}');
          fbq('track', 'PageView');
			`,
        }}
      ></Script>

      {/* Google Tag Manager - Global base code */}
      <Script
        id="Google Tag Manager "
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
			  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			  })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
			`,
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        id="gtag.js"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
      />

      <Script
        id="gtag"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());

					gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
					page_path: window.location.pathname,
					});
				`,
        }}
      ></Script>

      {/* Global Conversion Tracking */}
      <Script
        id=" Global Conversion Tracking"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
              function gtag_report_conversion(url) {
                var callback = function () {
                  if (typeof(url) != 'undefined') {
                    window.location = url;
                  }
                };
                gtag('event', 'conversion', {
                    'send_to': '${process.env.NEXT_PUBLIC_CONVERSION_TRACKING_ID}',
                    'event_callback': callback
                });
                return false;
              }
            `,
        }}
      />

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=441745470167810&ev=PageView&noscript=1"
        />
      </noscript>

      {/* Google Tag Manager */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      ></noscript>

      {/* {Start RudderStack} */}
      <Script
        id="Rudder Stack"
        dangerouslySetInnerHTML={{
          __html: `!function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId","getUserId","getUserTraits","getGroupId","getGroupTraits","startSession","endSession"],e.factory=function(t){return function(){e.push([t].concat(Array.prototype.slice.call(arguments)))}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
          e.load("${process.env.NEXT_PUBLIC_RUDDERSTACK_ID}","https://upgradbalcvjs.dataplane.rudderstack.com"),
          e.page()}();`,
        }}
      ></Script>

      {/* {End RudderStack} */}
    </>
  );
};

export default LazyLoadScripts;
