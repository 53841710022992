import React, { useState } from 'react';
import 'twin.macro';
import Link from 'next/link';

const SectionDisclaimer: React.FC = () => {
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <section tw=" sm:pt-6 pt-[60px] pb-8 text-sm leading-7">
      <p>
        Disclaimer: The content on the website and/or Platform is for informational and educational purposes only. The
        user of this website and/or Platform (User) should not construe any such information as legal, investment, tax,
        financial or any other advice. Nothing contained herein constitutes any representation, solicitation,
        recommendation, promotion or advertisement on behalf of KnowledgeHut and / or its Affiliates (including but not
        limited to its subsidiaries, associates, employees, directors, key managerial personnel, consultants, trainers,
        advisors). The User is solely responsible for evaluating the merits and risks associated with use of the
        information included as part of the content. The User agrees and covenants not to hold KnowledgeHut and its
        Affiliates responsible for any and all losses or damages arising from such decision made by them basis the
        information provided in the course and / or available on the website and/or platform. KnowledgeHut reserves the
        right to cancel or reschedule events in case of insufficient registrations, or if presenters cannot attend due
        to unforeseen circumstances. You are therefore advised to consult a KnowledgeHut agent prior to making any
        travel arrangements for a workshop. For more details, please refer to the{' '}
        <a
          href="/refund-policy"
          title="Cancellation &amp; Refund Policy"
          rel="nofollow"
          tw="text-[#26d400]  font-semibold"
        >
          {' '}
          Cancellation &amp; Refund Policy
        </a>
        .
      </p>
      <p tw="text-sm leading-7">
        CSM®, CSPO®, CSD®, CSP®, A-CSPO®, A-CSM® are registered trademarks of Scrum Alliance®. KnowledgeHut{' '}
        {showMore && (
          <span className="text-sm">
            {''}
            Solutions Pvt. Ltd. is a Registered Education Ally (REA) of Scrum Alliance®. PMP is a registered mark of the
            Project Management Institute, Inc. CAPM is a registered mark of the Project Management Institute, Inc.
            PMI-ACP is a registered mark of the Project Management Institute, Inc. PMI-RMP is a registered mark of the
            Project Management Institute, Inc. PMI-PBA is a registered mark of the Project Management Institute, Inc.
            PgMP is a registered mark of the Project Management Institute, Inc. PfMP is a registered mark of the Project
            Management Institute, Inc. KnowledgeHut Solutions Pvt. Ltd. is a Premier Authorized Training Partner (ATP)
            of Project Management Institute, Inc. The PMI Premier Authorized Training Partner logo is a registered mark
            of the Project Management Institute, Inc. PMBOK is a registered mark of the Project Management Institute,
            Inc. ITIL®, PRINCE2®, PRINCE2 Agile®, AgileSHIFT® are registered trademarks of AXELOS Limited, used under
            permission of AXELOS Limited. The Swirl logo™ is a trademark of AXELOS Limited, used under permission of
            AXELOS Limited. All rights reserved. COBIT® is a registered trademark of Information Systems Audit and
            Control Association® (ISACA®). (ISC)2® is a registered trademark of International Information Systems
            Security Certification Consortium, Inc. CompTIA Authorized Training Partner, CMMI® is registered in the U.S.
            Patent and Trademark Office by Carnegie Mellon University. FRM®, GARP™ and Global Association of Risk
            Professionals™, are trademarks owned by the Global Association of Risk Professionals, Inc. Global
            Association of Risk Professionals, Inc. (GARP™) does not endorse, promote, review, or warrant the accuracy
            of the products or services offered by KnowledgeHut for FRM® related information, nor does it endorse any
            pass rates claimed by the provider. Further, GARP is not responsible for any fees or costs paid by the user.
            IIBA®, the IIBA® logo, BABOK®, and Business Analysis Body of Knowledge® are registered trademarks owned by
            the International Institute of Business Analysis. KnowledgeHut is an Endorsed Education Provider of IIBA®.
            Scaled Agile Framework® and SAFe® are registered trademarks of Scaled Agile, Inc.® KnowledgeHut is a Gold 
            SPCT Partner of Scaled Agile, Inc®. KnowledgeHut is an Authorized Training Partner of CertNexus. KnowledgeHut
            is a Microsoft Partner. KnowledgeHut is an AWS Training Partner (ATP). KnowledgeHut is an ICAgile Member Training
            Organization. KnowledgeHut is a Professional Training Network member of scrum.org. KnowledgeHut is an
            Accredited Examination Centre of IASSC. KnowledgeHut is a Registered Education Partner (REP) of the DevOps
            Institute (DOI). KnowledgeHut is an ATO of PEOPLECERT. KnowledgeHut is an Authorized Training Partner (ATP)
            and Accredited Training Center (ATC) of EC-Council. KnowledgeHut is a Bronze Licensed Training Organization
            of Kanban University.{' '}
          </span>
        )}
        {showMore ? (
          <button
            title="Click to Show Less"
            tw="text-[#26d400]  font-semibold uppercase text-sm"
            onClick={() => setShowMore(false)}
          >
            Read Less
          </button>
        ) : (
          <button
            title="Click to Show More"
            tw="text-[#26d400]  font-semibold uppercase text-sm"
            onClick={() => setShowMore(true)}
          >
            Read More
          </button>
        )}
      </p>
    </section>
  );
};

export default SectionDisclaimer;
