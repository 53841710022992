// import Button from 'components/Category/common/Button';
import Link from "next/link";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CartBlack, HeaderSprite } from "../Icon";
// import BlogSubscribeModal from "../BlogSubscribeModal";
// import Button from "../Button/index";
import MegaMenu from "../MegaMenu";
import MenuItem from "../MenuItems";
import { MegaMenuIcon } from "../Icon";
import {
  BootcampData,
  EnterpriseData,
  HelpData,
  ResourcesData,
} from "../MenuItems/Data";
import { MenuItemsProps } from "../MenuItems/Interface";
import SearchBar from "../SearchBar";
import HeaderIcon from "./HeaderIcon";
import { trackGa4Events } from "../../../lib/events/ga4/tracker";
import MegaMenuShadowDOM from "./MegaMenushadow";
import ShadowAuthButton from "../ShadowAuthButton";
// import HeaderSearchBar from './HeaderSearchBar';

// type Props = {
// 	MenuItemsProps: MenuItemsProps;
// 	MegaMenuProps: any;
// };

const HeaderLargeScreen: React.FC = () => {
  // const [open, setOpen] = useState(false);
  const [isShowMegaMenu, setIsShowMegaMenu] = useState(false);
  useEffect(() => {
    if (isShowMegaMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isShowMegaMenu]);

  const closeOpen = () => {
    setIsShowMegaMenu(false);
    document.body.style.overflow = "auto";
  };
  const [headerHeight, setHeaderHeight] = useState(0);
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const { height, top } = ref.current.getBoundingClientRect();
      setHeaderHeight(height + top || 0);
      setHeight(height);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="container-fluid z-[99] px-[15px] 2xl:px-0 mx-auto md:w-[1440px] hidden lg:block"
    >
      <nav className="hidden lg:grid lg:grid-cols-2">
        <div className="flex  items-center">
          {/* Header Image */}
          <HeaderIcon />

          {/* MegaMenu */}
          <div
            className="h-full flex justify-center items-center"
            onMouseLeave={closeOpen}
          >
            <div className="">
              {isShowMegaMenu && (
                <div
                  onClick={closeOpen}
                  className={`absolute h-screen  w-screen z-10 top-[68px] ${
                    isShowMegaMenu ? "bg-black/70 inset-0 " : " bg-transparent"
                  }`}
                ></div>
              )}
            </div>
            <div className="mr-3 ml-12">
              <div>
                <div
                  className="flex relative items-center bg-[#f0f0f0]  h-9 px-3 cursor-pointer rounded justify-center border border-transparent"
                  onMouseEnter={() => {
                    setIsShowMegaMenu(true);
                    trackGa4Events("homepage_hover_courses_header");
                  }}
                >
                  <span className="text-xs font-semibold pr-3 text-custom-gray-200">
                    {" "}
                    Courses
                  </span>
                  <MegaMenuIcon />
                </div>
              </div>
            </div>

            {isShowMegaMenu && (
              <>
                <div
                  className={`absolute z-50 left-1/2 -translate-x-1/2 overflow-y-scroll`}
                  style={{
                    height: `calc(100vh - ${headerHeight}px)`,
                    top: `${height}px`,
                  }}
                >
                  <MegaMenuShadowDOM />
                </div>
              </>
            )}
          </div>

          {/* Search bar */}
          <div className="w-[281px]">
            <SearchBar />
          </div>
        </div>

        <div className="flex justify-end items-center">
          {/* Menu items */}
          <MenuItem
            toShow={0}
            title="Bootcamps"
            data={BootcampData}
            isShowNewTag={true}
          />
          <MenuItem toShow={0} title="Enterprise" data={EnterpriseData} />
          {/* Link to Blog */}

          {/* <a href="/blog">
            <div className="inline-block mr-5 py-5 text-gray-5 text-[13px] font-semibold border-b-2 border-white cursor-pointer hover:border-black leading-6">
              Blog
            </div>
          </a> */}

          {/* Menu items */}
          <MenuItem toShow={0} title="Resources" data={ResourcesData} />
          <MenuItem toShow={0} title="Help" data={HelpData} />

          {/* Cart icon */}
          <div className="flex justify-evenly mr-6">
            <div className="group">
              <div className="py-5 text-gray-5 h-[68px] text-[13px] font-semibold border-b-2 border-white cursor-pointer group-hover:border-black leading-[26px] duration-[0.3s]">
                <a href="/cart" className="block h-4">
                  <CartBlack className="mt-[5px]" />
                </a>
              </div>
            </div>
          </div>

          {/* SignIn button */}
          <div className="flex justify-evenly">
            {/* <a rel="nofollow" href="https://prism.knowledgehut.com/">
              <div className="inline-flex items-center justify-center h-9 text-[13px] leading-5 font-semibold text-custom-gray-200 bg-[#f0f0f0] px-[9px] py-2 rounded border border-transparent">
                Sign In
              </div>
            </a> */}
            <ShadowAuthButton />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderLargeScreen;
